// React
import { useCallback, useEffect, useState } from "react";

// Component
import Card from "./Card";

// React ROuter Dom Location
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DetailCardArea = ({ actionType, datas }) => {
  // Local State
  const [handleCountDatas, setHandleCountDatas] = useState(8);
  const [reload, setReload] = useState(false);
  // Translation
  const { t } = useTranslation();

  // Location
  const location = useLocation();

  const handleReload = useCallback(() => {
    setReload(true);
    setTimeout(() => setReload(false), 0);
  }, []);

  useEffect(() => {
    if (location) {
      setHandleCountDatas(8);
      // handleReload();
    }
  }, [location]);
  return (
    <section className="detail-card-area">
      <div className="container">
        <h6 className="caption">{actionType==="courses" ? t("header.courses"): t("header.conference")}</h6>
        <div className="card-area">
          {datas &&
            datas
              .slice(0, handleCountDatas)
              .map((item) => (
                <Card
                  data={item}
                  key={item.id}
                  type={actionType==="conferneces" ? "conferences" : "cource"}
                  reload={reload}
                />
              ))}
        </div>
      </div>
      {datas.length > 4 && (
        <div className="btn-area">
          <span
            className="btn"
            onClick={() => {
              setHandleCountDatas(handleCountDatas + 8);
            }}
          >
            {t("general.read_more")}
          </span>
        </div>
      )}
    </section>
  );
};

export default DetailCardArea;
