import { useTranslation } from "react-i18next";
import PersonaolCard from "../components/PersonaolCard";
const Spikers = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="detail-search-area">
        <div className="container">
          <div className="row">
            <div className="info">
            <p className="title">{t("general.parners-caption_primary")}</p>
            <span className="subtitle">{t("general.parners-caption_secondary")}</span>
              <form className="search">
                <div className="seacrh-icon">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5718 21.4972C16.8172 21.4972 21.0695 17.245 21.0695 11.9996C21.0695 6.75418 16.8172 2.50195 11.5718 2.50195C6.32645 2.50195 2.07422 6.75418 2.07422 11.9996C2.07422 17.245 6.32645 21.4972 11.5718 21.4972Z"
                      stroke="#001D48"
                      strokeWidth="1.49962"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22.0703 22.4976L20.0703 20.4976"
                      stroke="#001D48"
                      strokeWidth="1.49962"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  name=""
                  id=""
                  className="seacrh-inp"
                  placeholder={t("general.parners_search")}
                />
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="spikers-area">
        <div className="container">
          <h6 className="caption">{t("general.spiker")}</h6>
          <div className="spikers">
            <PersonaolCard/>
            <PersonaolCard/>
            <PersonaolCard/>
            <PersonaolCard/>
            <PersonaolCard/>
            <PersonaolCard/>
          </div>
          <div className="btn-area">
            <span className="btn">{t("general.read_more")}</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Spikers;
