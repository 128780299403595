
const Faq = () => {
  return (
    <section className='additional-page'>
      <div className="container">
        <h2 className="caption">Title</h2>
        <p className="text">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quasi possimus blanditiis, ipsa, molestias facere ipsam adipisci similique, dolor delectus vero nisi consequuntur alias! Nam magni cumque amet assumenda deleniti aliquam.lorem Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat assumenda reiciendis nulla non perspiciatis ullam maxime vel praesentium voluptatem in eius dignissimos tempora, architecto et ea, itaque temporibus rerum magnam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam tenetur, qui itaque quia ipsam sint quisquam voluptatem reiciendis velit animi repellendus vitae pariatur odit iusto sapiente accusamus voluptas aspernatur architecto?</p>
      </div>
    </section>
  )
}

export default Faq