// Toastie
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Bildirimi göstermek için bir fonksiyon
export const notify = (message) => {
  toast.info(message, {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    transition: Bounce,
  });
};
