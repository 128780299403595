/* ------------------------------- Sweet Alert ------------------------------ */
import Swal from "sweetalert2";

// Submit ALert
export const submitAlert = (title, text, confirmButtonText, onConfirm,onCancel) => {
  Swal.fire({
    title,
    text,
    showCancelButton: false,
    confirmButtonColor: "#000",
    buttonsStyling: false,
    confirmButtonText,
    width: "770px",
    customClass: {
      container: "sweetAlert-container",
      title: "your-title-style",
      closeButton: "swal-close-button",
      confirmButton: "sweetAlert-confirm-button",
      cancelButton: "sweetAlert-cancel-button",
    },
    showCloseButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    } else {
      onCancel()
    }
  });
};

// Info Alert
export const infoAlert = (title, text) => {
  Swal.fire({
    title,
    text,
    showCancelButton: false,
    showConfirmButton: false,
    confirmButtonColor: "#000",
    buttonsStyling: false,
    width: "770px",
    customClass: {
      container: "sweetAlert-container",
      title: "your-title-style",
      closeButton: "swal-close-button",
      confirmButton: "sweetAlert-confirm-button",
      cancelButton: "sweetAlert-cancel-button",
    },
    showCloseButton: true,
  });
};
