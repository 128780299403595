// Icons
import filterMenuIcon from "../../assets/img/icon/filterMenu.svg";
import arrrowRight from "../../assets/img/icon/arrow-right.svg";
import { useContext } from "react";
import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";

const CourseConferanceSearchFilterArea = () => {
  // Global State
  const { setSearchValue, setRenderType, setFIlterMenu } =
    useContext(MainContext);

  // Language
  const { t } = useTranslation();
  return (
    <div className="cource-confrance-search">
      <div className="container">
        <div className="row">
          <div className="filter" onClick={() => setFIlterMenu(true)}>
            <div className="icon">
              <img src={filterMenuIcon} alt="menu" />
            </div>
            {t("side_bar.search_filter")}
            <div className="icon">
              <img src={arrrowRight} alt="arrrowRight" />
            </div>
          </div>
          <form className="search-form">
            <input
              name="seacrh"
              placeholder="Axtar"
              onChange={(e) => {
                if (e.target.value.length >2) {
                  setSearchValue(e.target.value);
                  setRenderType("search");
                } else {
                  setRenderType("");
                }
              }}
              autoComplete="off"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default CourseConferanceSearchFilterArea;
