const Agreement = () => {
  return (
    <section className="additional-page">
      <div className="container">
        <div className="content">
          <p>
            <strong>"KursEvi" Məlumatlı Razılıq Sazişi </strong>
          </p>
          <p>
            <em>Sonuncu dəyişiklik: 10 May, 2020–ci il</em>
          </p>
          <p>
            <strong>Xidmətlərin təsviri</strong>
          </p>
          <p>
            <strong>“KursEvi</strong>
            <strong>”-</strong>
            <strong>un Mütəxəssisləri</strong>” keçidində Tibb Mütəxəssisləri,
            onlarla sizin eyni fiziki yerdə olmadığınız hallarda interaktiv
            audio və video texnologiyalardan istifadə edərək səhiyyə xidmətləri
            göstərirlər. Siz Tibb Mütəxəssisi ilə müalicə ilə bağlı davamlı
            əlaqə yarada bilərsiniz, lakin sizin ilk səfəriniz konsultasiya kimi
            başlaya (məs. sizin üçün ən uyğun müalicə prosedurunu
            müəyyənləşdirmək üçün) bilər və bu zəruri olaraq davamlı müalicə
            əlaqəsinə gətirib çıxarmayacaqdır. Siz Tibb Mütəxəssisi tərəfindən
            tövsiyyə edildikdə və ya digər şəkildə lazım olduqda, təcili tibbi
            yardım dəstəyi və ya sonrakı tibbi qayğı üçün müraciət edə və
            tövsiyyə olunan qaydada digər səhiyyə təminatçıları ilə
            məsləhətləşməyə davam edə bilərsiniz. Xidmətlərimizin üstünlükləri
            arasında səhiyyə xidmətlərindən istifadənin təkmilləşdirilməsi və
            rahatlıq daxildir. Lakin istənilən səhiyyə xidmətində olduğu kimi,
            texnologiyadan istifadə ilə bağlı potensial risklər də mövcuddur.
          </p>
          <p>
            <strong>
              <em>Bu risklərə, bunlarla</em>
            </strong>{" "}
            <strong>
              <em>məhdudlaşmamaqla, aşağıdakılar daxildir:</em>
            </strong>
          </p>
          <ul>
            <li>
              Nadir hallarda, ötürülən məlumat səhiyyə və ya sağlamlıqla bağlı
              qərarların qəbul edilməsi üçün yetərsiz ola bilər.
            </li>
            <li>
              Elektron avadanlığın (cihazların) və ya internet bağlantısının
              uğursuzluğuna görə kənarlaşmalar və ya fasilələr ola bilər. Bu
              kimi hallar baş verdikdə, sizinlə telefon və ya digər əlaqə
              vasitələri ilə əlaqə saxlanıla bilər.
            </li>
            <li>
              Nadir hallarda, sağlamlıq qeydlərinizin və ya ambulator və ya
              xəstəlik tarixçənizə bütövlükdə çıxış olmaması və ya sizin
              tərəfinizdən tam məlumat verilməməsi hallarında, bu dərman
              vasitələrinin mənfi təsirləri və ya digər təsirlərlə nəticələnə
              bilər.
            </li>
            <li>
              Sağlamlıq məlumatlarınızın məxfiliyini qorumaq üçün etibarlı
              təhlükəsizlik protokollarını tətbiq etsək də, nadir hallarda
              təhlükəsizlik protokolları da uğursuz ola və bu da məxfiliyin
              pozulmasına səbəb ola bilər.
            </li>
          </ul>
          <p>
            <strong>
              <em>
                Bu razılığı qəbul edərək siz aşağıdakıları başa düşdüyünüzü və
                onlar ilə razılaşdığınızı təsdiq edirsiniz:
              </em>
            </strong>
          </p>
          <ul>
            <li>
              Bizdən qəbul etdiyiniz səhiyyə xidmətləri və məsləhətlər haqqında
              məlumatları tibbi sığorta şirkətiniz ilə paylaşa bilərik. Buraya
              Məxfilik Siyasətimizin şərtlərinə əsasən genetik müayinə, dərman
              (maddə) asılılığı, əqli sağlamlıq, ötürülə bilən xəstəliklər və
              digər sağlamlıq vəziyyətləri haqqında məlumatlar aid ola bilər.
            </li>
            <li>
              Sağlamlıq sığortası paketiniz Xidmətlər üçün ödənişi tam olaraq
              etmədiyi halda, siz ödəniş üçün tamamilə və ya qismən cavabdehlik
              daşıya bilərsiniz. Tibb Mütəxəssislərimiz sağlamlıqla bağlı
              qeydlərinizi səhiyyə xidmətlərinin göstərilməsi, koordinasiyası və
              ya idarə edilməsi ilə əlaqəli məqsədlər üçün digər səhiyyə xidməti
              təminatçıları ilə paylaşa bilər ki, sizin səhiyyə ehtiyaclarınızı
              qarşılaya bilsinlər.
            </li>
            <li>
              Buraya “<strong>KursEvi”</strong> Məxfilik Siyasətinin
              şərtlərinə əsasən genetik müayinə, psixotrop və s. maddə
              asılılığı, əqli sağlamlıq, ötürülə bilən xəstəliklər və digər
              sağlamlıq vəziyyətləri haqqında məlumatlar aid ola bilər.
            </li>
            <li>
              Biz klinik xidmətlərimizin sizin müalicə ehtiyaclarınızın bəziləri
              və ya hamısı üçün müvafiq olmadığını müəyyənləşdirə və Sayt
              vasitəsilə sizə klinik xidmətlər göstərməməyi seçə bilərik.
            </li>
            <li>
              Psixoloji yardım və psixoterapiya ilə əlaqədar olaraq, siz,
              terapiya metodları, istifadə edilən texnika və üsullar, terapiya
              müddəti (məlum olarsa) və ödəniş haqlarının strukturu barədə Tibb
              Mütəxəssislərindən məlumatları almaq hüququna maliksiniz. Siz
              istənilən zaman digər tibb işçisindən ikinci rəy ala və ya
              terapiyanı dayandıra bilərsiniz.
            </li>
            <li>
              Hesabınızda qeydiyyatdan keçirilmiş bütün uşaqların qəyyumu və ya
              şəxsi nümayəndəsi kimi çıxış etmək üçün hüquqi səlahiyyətinizin
              olduğunu təsdiq edirsiniz.
            </li>
            <li>Heç bir nəticəyə zəmanət və ya əminlik verilə bilməz.</li>
          </ul>
          <p>
            <strong>Qrup terapiyası </strong>
          </p>
          <p>
            Siz və Tibb Mütəxəssisi qrup və ya cüt şəkildə psixoloji yardımı və
            ya psixoterapiyanı keçirmək qərarına gələrsinizsə, (ümumilikdə “Qrup
            terapiyası”), bu zaman Qrup Terapiyasında müzakirə edilən
            məlumatların psixoloji dəstək və ya terapevtik məqsədlər üçün
            olduğunu və Qrup Terapiyasının iştirakçılarına aid olan hər hansı
            hüquqi icraatda istifadə üçün nəzərdə tutulmadığını başa
            düşürsünüz.&nbsp; Digər Qrup Terapiyasının lehinə və ya əleyhinə
            ifadə vermək üçün Tibb Mütəxəssisini məhkəməyə çağırmamağa və ya
            digər Qrup Terapiyasının iştirakçılarına qarşı qeydləri məhkəməyə
            təqdim etməməyə razılaşırsınız. Hər hansı Qrup Terapiyası
            iştirakçısının telefonda və ya digər şəkildə ayrılıqda Tibb
            Mütəxəssisinə söylədiyi istənilən fikrin digər Qrup terapiyası
            iştirakçıları ilə paylaşıla biləcəyinin yalnız mütəxəssisin
            ixtiyarında olduğunu başa düşürsünüz. Terapiya prosesi, o cümlədən
            hədəfin müəyyənləşdirilməsi və sonlandırma üçün məsuliyyəti Tibb
            Mütəxəssisi ilə paylaşmağa razılaşırsınız.
          </p>
          <p>
            <strong>Mesajlaşma</strong>
          </p>
          <p>
            050-111-11-11 telefon nömrəsi ilə “<strong>Üzvlərin Dəstəyi</strong>
            <strong>”</strong> komandamız ilə əlaqə saxlayaraq Tibb
            Mütəxəssisinə mesaj göndərə bilərsiniz. Üzv Dəstəyi və ya Tibb
            Mütəxəssisinə göndərilən elektron poçt məktubları (email) və ya
            elektron mesajlara dərhal cavab verilməyə bilər. Təcili tibbi yardım
            gözləyirsinizsə, bu zaman 103 və ya 113 nömrəsinə zəng etməli,
            təcili tibbi yardım çağırmalı və ya yaxınlıqda yerləşən təcili tibbi
            yardım stansiyasına getməlisiniz.
          </p>
          <p>
            <em>
              “<strong>KursEvi”–in Tibb Mütəxəssisləri </strong>ümumi
              olaraq <strong>“KursEvi</strong>
            </em>
            <strong>
              <em>”-un M</em>
            </strong>
            <strong>
              <em>ütəxəssisləri”</em>
            </strong>
            <em>
              &nbsp;adlandırılan müstəqil peşəkarlar kimi və ya müvafiq tibb
              müəssisəsinin tərkibində fəaliyyət göstərir.{" "}
            </em>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Agreement;
