const Privacy = () => {
  return (
    <section className="additional-page">
      <div className="container">
        <div className="content" >
          <p>
            <strong>KursEvi  Məxfilik Siyasəti</strong>
          </p>
          <p>
            <em>Sonuncu düzəliş: 20 May 2020-ci il</em>
          </p>
          <p>
            <strong>
              <em>Sağlamlıq haqqında məlumatların məxfiliyinə dair bildiriş</em>
            </strong>
          </p>
          <p>
            <strong>“KursEvi”</strong>&nbsp;vasitəsilə Xidmətlər göstərən
            Tibb Mütəxəssisləri ümumilikdə “
            <strong>KursEvi  peşəkarları” </strong>adlandırılan, müstəqil
            peşəkarlardır və ya müvafiq tibb müəssisələrinin tərkibində
            fəaliyyət göstərir. Məxfilik Siyasətimizin bu bölməsində (I Bölmə,
            “Sağlamlıq haqqında məlumatların məxfiliyinə dair bildiriş” və ya
            “Bildiriş”) “<strong>KursEvi”</strong> Peşəkarlarının (və ya{" "}
            <strong>KursEvi </strong>&nbsp; peşəkarlarının adından çıxış
            edən zaman “<strong>KursEvi</strong>” MMC) sizin
            haqqınızda sağlamlıq məlumatlarından necə istifadə edə biləcəklərini
            və onları necə açıqlayacaqlarını və sizin bu məlumatlara necə daxil
            olacağını təsvir edir. Diqqətlə bu bölməyə nəzər yetirin.
          </p>
          <p>
            <strong>
              <em>Pasiyent (xəstə) barədə məxfilik necə qorunur?</em>
            </strong>
          </p>
          <p>
            <strong>“KursEvi”</strong>&nbsp;vasitəsilə online (virtual)
            tibb və klinik xidmətlər göstərən tibb mütəxəssisləri kimi (“Tibb
            Mütəxəssisləri”, “bizi/ bizə”, “biz”, “bizim”) biz başa düşürük ki,
            siz və sağlamlığınız haqqında olan məlumatlar şəxsi məlumatlardır.
            Buna görə də, biz, sağlamlığınız haqqındakı məlumatların məxfiliyini
            qorumağa çalışırıq. Biz davamlı olaraq inzibati, fiziki və texniki
            vasitələrlə həmin məlumatları qorumağa və digər şəkildə tətbiq
            olunan dövlət qaydalarına əməl etməyə çalışırıq.
          </p>
          <p>
            <strong>
              <em>
                Sağlamlıq haqqında məlumatlardan necə istifadə edirik və onları
                necə açıqlayırıq?{" "}
              </em>
            </strong>
          </p>
          <p>
            Sağlamlıq məlumatlarınızı müalicə, ödəniş və səhiyyə əməliyyatları
            kateqoriyalarına aid hesab etdiyi normal fəaliyyətlər üçün istifadə
            edirik. Aşağıda həmin fəaliyyətlərə dair nümunələr təqdim olunur,
            baxmayaraq ki, hər bir kateqoriyaya daxil olan hər bir istifadə və
            ya açıqlama halı qeyd olunmur:
          </p>
          <ul>
            <li>
              <strong>Müalicə</strong>– Bizə təqdim etdiyiniz sağlamlıq
              məlumatlarının qeydiyyatını aparırıq. Bura sizin analizlərinizin
              nəticələri, diaqnozlarınız, istifadə etdiyiniz dərman vasitələri,
              dərman vasitələrinə reaksiyanız və ya digər terapiyalar və online
              Xidmətlər vasitəsilə tibbi vəziyyətiniz barədə əldə etdiyimiz
              məlumatlar daxil ola bilər. Biz bu məlumatları digər həkimlərə,
              orta tibb personalına və laboratoriya kimi qurumlara sizin
              sağlamlığınızın mühafizəsi və sizin tibbi qayğıya olan
              ehtiyaclarınızı qarşılamaq üçün istifadə edir və açıqlaya bilərik.
            </li>
            <li>
              <strong>Ödəniş</strong>– Biz sizə qayğı göstərən zaman əldə
              etdiyiniz xidmətləri və təminatları, sizin, sığorta şirkətinizin
              &nbsp;və ya digər üçüncü tərəfin bizə ödəniş etməsi üçün&nbsp;
              sənədləşdiririk. Sağlamlıq planınıza əsasən əvvəlcədən təsdiq
              tələb edən növbəti müalicə proseduru və ya xidmətlər haqqında
              sağlamlıq planınız üçün məsuliyyət daşıyan quruma məlumat verə
              bilərik.
            </li>
            <li>
              <strong>Səhiyyə əməliyyatları</strong>– Sağlamlıq məlumatlarından
              göstərdiyimiz xidmətləri təkmilləşdirmək, tibbi və qeyri-tibbi
              heyəti təlimatlandırmaq, biznes idarəetməsi, keyfiyyətin
              qiymətləndirilməsi və yaxşılaşdırılması və müştəri xidmətlərinin
              yaxşılaşdırılması üçün istifadə edilir. Məsələn, biz sağlamlığınız
              haqqında məlumatlardan müalicəmizi və xidmətlərimizi təhlil
              etməkdən və sizə qayğı göstərən heyətimizin fəaliyyətini
              qiymətləndirmək üçün istifadə edilə bilərik.
            </li>
          </ul>
          <p>
            Biz həmçinin sizin sağlamlıq haqqında məlumatlarınızdan aşağıdakılar
            üçün istifadə edə bilərik:
          </p>
          <ul>
            <li>Açıqlamanı tələb edən milli qanunlara əməl etmək</li>
            <li>
              Xəstəliklərin və ya tibbi cihazların izlənilməsi üçün ictimai
              səhiyyə fəaliyyətlərinə kömək etmək
            </li>
            <li>
              Sui–istifadə və ya laqeydlik qurbanlarını qorumaq üçün
              səlahiyyətli orqanları məlumatlandırmaq
            </li>
            <li>
              Saxtakarlıq və dələduzluq hallarının araşdırılması kimi dövlət
              səhiyyə nəzarəti fəaliyyətlərinə əməl etmək
            </li>
            <li>
              Hüquq–mühafizə orqanlarının rəsmilərinə və ya məhkəmə qərarlarına,
              məhkəmə çağırışlarına və ya qanunla müəyyən edilmiş digər
              proseslərə cavab vermək
            </li>
            <li>
              İstintaq, tibbi ekspertlər və dəfn qurumlarının öz vəzifələrini
              yerinə yetirmək üçün zəruri məlumatlar ilə təmin etmək
            </li>
            <li>
              Məxfilik və tədqiqat ehtiyaclarının tarazlaşdırılmasını təmin
              etmək məqsədilə daxili analiz protokollarından sonra araşdırma və
              tədqiqat aparmaq
            </li>
            <li>
              Sağlamlıq və ya təhlükəsizlik üçün ciddi təhdidin qarşısını almaq
            </li>
            <li>
              Milli təhlükəsizlik və sağlamlığın mühafizəsini təmin edən
              xidmətlər kimi ixtisaslaşmış hökümət qurumlarının fəaliyyətlərinin
              icrasına kömək etmək
            </li>
            <li>
              Əgər silahlı qüvvələrin üzvüsünüzsə (aktiv və ya ehtiyatda), hərbi
              və veteranlarla əlaqəli olan dövlət orqanlarını məlumatlandırmaq
            </li>
            <li>
              İşdə xəsarət almısınızsa, işçilərin kompensasiya daşıyıcılarını və
              ya işəgötürəni məlumatlandırmaq
            </li>
            <li>Müalicə alternativlərini tövsiyyə etmək</li>
            <li>
              Sağlamlıqla əlaqəli məhsullar və xidmətlər haqqında sizə məlumat
              vermək
            </li>
            <li>
              Müalicə, ödəniş və ya səhiyyə əməliyyatları üçün təşkilatınız
              daxilində əlaqə qurmaq
            </li>
            <li>
              Müalicə, ödəniş fəaliyyətləri və ya keyfiyyətin qiymətləndirilməsi
              və təkmilləşdirilməsi, qayğının koordinasiyası və tibb
              mütəxəssisləri üçün ixtisasartırma və təlimlər ilə bağlı səhiyyə
              əməliyyatları üçün digər təminatçılar, sığorta planları və ya
              digər əlaqədar qurumlarla əlaqə yaratmaq
            </li>
            <li>
              Biz həmçinin əməliyyat məqsədləri üçün sizin şəxsi və ya sağlamlıq
              məlumatlarınızdan istifadə edə və ya onları açıqlaya bilərik.
              Məsələn biz sizə göstərilən qayğıda və ya həmin qayğı üçün edilən
              ödənişdə iştirak edən şəxslər, məs. dostlarınız və ailəniz ilə
              əlaqə saxlaya və təyinat / görüş haqqında xatırlatmalar göndərə
              bilərik.
            </li>
          </ul>
          <p>
            Əvvəldə təsvir olunmayan bütün digər istifadə halları və açıqlamalar
            yalnız sizin icazəniz ilə yerinə yetirilə bilər. Biz həmçinin sizin
            icazənizi marketinq məqsədləri üçün sağlamlıq məlumatlarınızdan
            istifadə etməzdən, onları açıqlamazdan və ya məlumatlarınızı üçüncü
            tərəfə ötürməzdən əvvəl əldə edəcəyik. Siz istənilən vaxt icazənizi
            geri götürə bilərsiniz; lakin bu əvvəlki istifadə və açıqlama
            hallarına təsir etməyəcəkdir.
          </p>
          <p>
            <strong>
              <em>
                Tibb Mütəxəssislərinin məsuliyyət və vəzifələri nələrdən
                ibarətdir?
              </em>
            </strong>
          </p>
          <p>Qanunla bizdən aşağıdakılar tələb edilir:</p>
          <ul>
            <li>Sağlamlıq haqqında məlumatlarınızın məxfiliyini qorumaq</li>
            <li>
              Vəzifələrimiz və məxfilik təcrübələri haqqında bu bildirişi təmin
              etmək
            </li>
            <li>Hal–hazırda qüvvədə olan bildirişin şərtlərinə əməl etmək</li>
            <li>
              Sağlamlıq haqqında məlumatlarınıza xələl yetirən pozuntu halları
              barədə məlumat vermək.
            </li>
          </ul>
          <p>
            Biz məxfilik praktikalarımızı dəyişdirmək və saxladığımız bütün
            məlumatlar üçün yeni təcrübələr tətbiq etmək hüququna sahibik.
            Yenidən işlənmiş bildirişlər <strong>Virutal Doktor</strong>
            &nbsp;vebsaytında və mobil tətbiqində yayımlanacaqdır.
          </p>
          <p>
            <strong>
              <em>Səhiyyə ilə bağlı hər hansı bir hüquqlarınız varmı? </em>
            </strong>
          </p>
          <p>Qanun sizə aşağıdakı hüquqları verir:</p>
          <ul>
            <li>
              Sağlamlıq haqqında məlumatlarınızın müəyyən hissələrini yoxlamaq
              və kopyalamaq. Məhdud hallarda tələblərinizdən imtina edə bilərik.
              Siz sağlamlığınız haqqında qeydləri sizə elektron formatda təqdim
              etməyimizi tələb edə bilərsiniz.
            </li>
            <li>
              Sağlamlığınız haqqında məlumatların düzgün olmadığını və ya
              natamam olduğunu düşündüyünüz halda onların dəyişdirilməsini tələb
              edə bilərsiniz. Lakin müəyyən hallarda biz bu tələbinizdən imtina
              edə bilərik.&nbsp;
            </li>
            <li>
              Əvvəlki illər üzrə edilmiş sağlamlığınız haqqında məlumatların
              müəyyən açıqlanmalarının uçotunu (hesabatını) qəbul etmək,
              baxmayaraq ki, buraya müalicə, ödəniş və səhiyyə əməliyyatları
              üçün açıqlamalar daxil deyil.
            </li>
            <li>
              Sağlamlığınız haqqında məlumatlardan necə istifadə etdiyimizi və
              ya açıqladığımızı məhdudlaşdırmağımızı tələb etmək. Lakin
              ödəyiciyə təqdim etdiyimiz məlumatları məhdudlaşdırmağımızı tələb
              etmədiyiniz halda, bizdən sizin tələb və sorğular ilə razılaşmaq
              tələb olunmur, bu halda, açıqlama ödəyicinin ödənişi və ya səhiyyə
              əməliyyatları ilə bağlı olmalı və siz səhiyyə xidmətləri üçün
              tamamilə özünüz ödəniş etmiş olursunuz.
            </li>
            <li>
              Xüsusi telefon nömrəsi, poçt və ya e-poçt ünvanı ilə sizinlə əlaqə
              saxlamağı tələb etmək.
            </li>
            <li>
              Hətta elektron versiyasını alsanız belə, bu bildirişin kağız
              nüsxəsini əldə etmək. Biz tələb və sorğuların bəzilərini yazılı
              şəkildə təqdim etməyi tələb edə bilərik.
            </li>
          </ul>
          <p>
            <strong>
              <em>Şikayətim olarsa?... </em>
            </strong>
          </p>
          <p>
            Məxfiliyin qorunmadığını düşünürsünüzsə, bununla bağlı öz
            şikayətinizi bildirə bilərsiniz. Bununla bağlı şikayət etmək və ya
            bu barədə ətraflı məlumat əldə etmək üçün aşağıdakı ünvan ilə əlaqə
            saxlayın: <u>info@videodoktor.az</u>
          </p>
          <p>
            <strong>
              <em>Bu bildirişə kim əməl edəcəkdir?</em>
            </strong>
          </p>
          <p>
            Bu bildirişdə aşağıdakılara dair səhiyyə (sağlamlıq) təcrübələri
            təsvir edilir:
          </p>
          <ul>
            <li>
              Sizin sağlamlıq qeydlərinizə giriş hüququ əldə etmək və/və ya
              məlumatlar daxil etmək səlahiyyətinə malik hər hansı tibb
              mütəxəssisi
            </li>
            <li>
              <strong>“KursEvi ”</strong> və “
              <strong>KursEvi  Peşəkarları”nın </strong>online sağlamlıq
              xidmətlərini göstərdikləri bütün şöbələr və bölmələr
            </li>
            <li>bütün asılı tərəflər və könüllülər.</li>
          </ul>
          <p>
            <strong>
              <em>&nbsp;“KursEvi ” Saytının Məxfilik Siyasəti </em>
            </strong>
          </p>
          <p>
            <strong>
              <em>Giriş </em>
            </strong>
          </p>
          <p>
            Məxfilik Siyasətimizin bu Bölməsində (II Bölmə, "
            <strong>Saytın Məxfilik Siyasəti</strong>") Saytdan və ya
            Xidmətlərdən istifadə edən zaman sizdən və / və ya haqqınızda
            məlumatları necə topladığımız, istifadə etdiyimiz və açıqladığımız
            təsvir olunur.
          </p>
          <p>
            SAYT SİZİN HAQQINIZDA ŞƏXSİ, TİBBİ VƏ SAĞLAMLIQ İLƏ BAĞLI
            MƏLUMATLARI TOPLAYACAQ VƏ ÖTÜRƏCƏKDİR. SAYTDAN İSTİFADƏ EDƏRƏK SİZ
            BİZİM MƏLUMATLARI TOPLAYA VƏ SİZİN ŞƏXSİ VƏ DİGƏR MƏLUMATLARINIZDAN
            BU SAYTIN MƏXFİLİK SİYASƏTİNDƏ TƏSVİR EDİLDİYİ KİMİ İSTİFADƏ EDƏ
            BİLƏCƏYİMİZ İLƏ RAZILAŞIRSINIZ. BUNUNLA RAZI DEYİLSİNİZSƏ, SAYTDAN
            İSTİFADƏ ETMƏMƏYİNİZ XAHİŞ OLUNUR.
          </p>
          <p>
            <strong>
              <em>Vacib anlayışlar </em>
            </strong>
          </p>
          <p>
            Hazırki Məxfilik Siyasətində “<strong>Şəxsi Məlumatlar</strong>”
            terminini istifadə etdikdə, sizə aid olan məlumatları, məs., əlaqə
            məlumatlarınızı (yəni, ad, ünvan, email ünvanı və ya telefon
            nömrəsi), şəxsən müəyyənləşdirilə bilən sağlamlıq və ya tibbi
            məlumatları (“<strong>Sağlamlıq məlumatları”)</strong> və həmin
            məlumatlar ilə əlaqəli hər hansı digər qeyri –ictimai məlumatları
            nəzərdə tuturuq. “Müəyyən edilməmiş məlumatlar” terminini istifadə
            edən zaman biz hər hansı şəxsin şəxsən kimliyini müəyyənləşdirmək
            üçün istifadə edilməmiş, nə də istifadə edilməsi nəzərdə tutulmayan
            məlumatları nəzərdə tuturuq. “<strong>Cookies</strong>” terminindən
            istifadə edən zaman biz vebsayta baxarkən Saytın sizin brauzerə
            göndərdiyi kiçik məlumat hissələrini nəzərdə tuturuq. Sonda, “Pixel”
            terminindən istifadə edərkən, biz, Sayta daxil olmaq üçün istifadə
            etdiyiniz cihaz və bu Məxfilik Siyasətinə əsasən üçüncü tərəflər ilə
            paylaşıla biləcək Saytdan istifadəniz haqqında məlumat əldə etmək
            üçün Saytın müəyyən hissələrində yerləşdirilə bilən HTML kod
            hissələrini nəzərdə tuturuq.
          </p>
          <p>
            <strong>
              <em>18 yaşından aşağı uşaqlar</em>
            </strong>
          </p>
          <p>
            Biz bilərəkdən 18 yaşından aşağı olan şəxslərə Saytımıza daxil olmaq
            imkanı verən Hesabları yaratmağa icazə vermirik.&nbsp;&nbsp;
          </p>
          <p>
            <strong>
              <em>
                Topladığımız və ya saxladığımız şəxsi məlumatlara aşağıdakılar
                daxil ola bilər:
              </em>
            </strong>
          </p>
          <ul>
            <li>
              Adınız, yaşınız, email ünvanınız, istifadəçi adınız, parolunuz və
              digər qeydiyyat məlumatları
            </li>
            <li>
              Tibbi və ya sağlamlıq tarixçəniz, sağlamlıq vəziyyətiniz və
              laboratoriya sınaq nəticələriniz, diaqnostik görüntüləriniz və
              digər sağlamlıq ilə əlaqəli məlumatlarınız ilə bağlı
              informasiyaların və ya qeydlərin daxil ola biləcəyi, bizə təqdim
              etdiyiniz sağlamlıq məlumatları
            </li>
            <li>
              Tibbi və terapiya qeydləri, müalicə və müayinə qeydləri və digər
              sağlamlıq ilə bağlı məlumatlar kimi Sayt vasitəsilə klinik
              xidmətlər göstərən Tibb Mütəxəssisləri tərəfindən hazırlanan və ya
              haqqınızda əldə olunan &nbsp;sağlamlıq məlumatları
            </li>
            <li>
              Hansı İnternet Brauzerdən, istifadə etdiyiniz kompüter və ya mobil
              cihazın növü və Saytdan necə istifadə etdiyiniz haqqında digər
              məlumatlar kimi istifadə etdiyiniz kompüter və ya mobil cihaz
              haqqında məlumatlar
            </li>
            <li>
              Sayt və ya əlaqədar xidmətlərə daxil etdiyiniz digər məlumatlar.
            </li>
          </ul>
          <p>
            <strong>
              <em>
                Biz sizin Şəxsi Məlumatlarınızdan aşağıdakı məqsədlər üçün
                istifadə edə bilərik{" "}
              </em>
            </strong>
            (I Bölmədə təsvir olunan Sağlamlıq Məlumatlarından istifadə ilə
            bağlı məhdudiyyətlərə əsasən):
          </p>
          <ul>
            <li>Sizi Xidmətlər ilə təmin etmək</li>
            <li>
              Keyfiyyət analizləri və oxşar fəaliyyətləri yerinə yetirməklə
              səhiyyə xidmətlərinin keyfiyyətini təkmilləşdirmək
            </li>
            <li>
              Xidmətlərdən istifadə ilə bağlı məcmu statistika kimi müəyyən
              edilməmiş məlumatlar hazırlamaq
            </li>
            <li>Sayt yenilikləri mövcud olduqda sizə məlumat vermək</li>
            <li>Saytı və Xidmətləri sizə təqdim etmək və təşviq etmək</li>
            <li>
              Şəxsi Məlumatlarınızı bizə təqdim etdiyiniz istənilə digər məqsədi
              yerinə yetirmək
            </li>
            <li>
              Sağlamlıq Məlumatlarından istifadə ilə bağlı I Bölmədə təsvir
              olunan məqsədlər
            </li>
            <li>Bizə icazə verdiyiniz hər hansı digər məqsəd üçün.</li>
          </ul>
          <p>
            <strong>
              <em>
                Biz həmçinin topladığımız və ya sizin təqdim etdiyiniz Şəxsi
                Məlumatları aşağıdakı tərəflərə açıqlaya bilərik{" "}
              </em>
            </strong>
            <em>
              (I Bölmədə təsvir olunan Sağlamlıq Məlumatlarından istifadə ilə
              bağlı məhdudiyyətlərə əsasən):{" "}
            </em>
          </p>
          <ul>
            <li>Törəmə müəssisələrimiz və filiallarımıza</li>
            <li>
              Podratçılarımıza, xidmət provayderlərimizə və biznesimizi
              dəstəkləmək üçün istifadə etdiyimiz və şəxsi məlumatları məxfi
              saxlamaq üçün müqavilə öhdəlikləri ilə tabe olduğumuz digər üçüncü
              tərəflərə
            </li>
            <li>
              Məhkəmə qərarı ilə tələb edildikdə məlumatların təqdim edilməsi də
              daxil olmaqla, qanunla tələb edilən qaydada
            </li>
            <li>
              Vicdanla məlumatların açıqlanmasının sizin və başqalarının
              təhlükəsizliyini qorumaq, hüquqlarımızı qorumaq, saxtakarlıq
              hallarını aşkarlamaq və ya hökümət sorğusuna cavab vermək üçün
              zəruri olduğuna inandıqda
            </li>
            <li>
              Fəaliyyətin fasiləsizliyi prinsipi əsasında və ya müflisləşmə,
              likvidasiya və ya oxşar icraatın bir hissəsi olmasından asılı
              olmayaraq, Sayt tərəfindən saxlanılan Şəxsi Məlumatların ötürülən
              aktivlər arasında olduğu, <strong>KursEvi  </strong>
              aktivlərinin hamısının və ya müəyyən hissəsinin satışı və ya
              transferi yaxud birləşmə, bölüşdürmə, yenidən təşkil və ləğv
              zamanı alıcıya və ya digər təyin edilmiş tərəfə.
            </li>
            <li>
              Məlumatları təqdim etdiyiniz zaman tərəfimizdən açıqlanan hər
              hansı digər məqsəd üçün.
            </li>
          </ul>
          <p>
            <strong>
              <em>Texnologiya vasitəsilə topladığımıız məlumatlar </em>
            </strong>
          </p>
          <p>
            Sayt və ya Xidmətlərdən istifadə etdikcə, müəyyən məlumatlar, Vahid
            Resurs Lokatorları (URL- lar) və üçüncü tərəf izləmə xidmətləri, o
            cümlədən aşağıdakılar haqqında naviqasiya məlumatları və müəyyən
            məlumatlar Cookies tərəfindən passiv şəkildə toplana bilər:
          </p>
          <ul>
            <li>
              <strong>Saytın fəaliyyəti haqqında məlumatlar</strong>. Saytda
              apardığınız axtarışların məzmunu kimi Saytda yerinə yetirdiyiniz
              əməliyyatlardan bəzilərinin qeydini apara bilərik.
            </li>
            <li>
              <strong>Giriş cihazı və brauzer məlumatları</strong>. Sayta
              kompüter və ya digər cihazdan daxil olduqda, biz, İnternet
              protokol ünvanınız, brauzer tipiniz, əlaqə / bağlantı sürəti və
              giriş vaxtları kimi həmin cihazdan anonim məlumatları toplaya
              bilərik (ümumilikdə “Anonim məlumatlar”).
            </li>
            <li>
              <strong>Cookies</strong>. Biz Sayt və Xidmətlərdən istifadə
              edilməsini asanlaşdırmaq, reklamımızı daha yaxşı etmək və həm
              sizi, həm də <strong>KursEvi </strong>–u qorumaq üçün həm
              sessiya Cookies – dən (veb brauzerinizi bağlayan vaxt qüvvədən
              düşən), həm də davamlı Cookies – dən istifadə edə bilərik. Siz
              seçimləri dəyişərək, Cookies–lərin qəbul edilməsini dayandırmaq və
              daxil olduğunuz vebsaytlardan Cookie qəbul etməzdən əvvəl sizi
              həvəsləndirmək barədə brauzerinizə göstərişlər verə bilərsiniz.
              Lakin siz Cookies-ləri qəbul etmədiyiniz halda , Sayta daxil olmuş
              vəziyyətdə qala bilməyəcəksiniz. Biz həmçinin Sayt və Xidmətlərdən
              istifadəni asanlaşdırmaq və məsələn istifadə modellərini
              ümumiləşdirməklə reklamlarımızı daha yaxşı etmək üçün Piksellərdən
              də istifadə edə bilərik. Hal–hazırda, biz, Saytımızın bütün
              hissələrində “İzləməyin” ("Do Not Track”) sorğularını təsdiq
              etmirik.
            </li>
            <li>
              <strong>Real vaxt məkanı</strong>. Saytın müəyyən xüsusiyyətləri
              cihazınızın yeri haqqında real vaxt məlumatlarını toplamaq üçün
              GPS texnologiyasından istifadə edir ki, Sayt sizin olduğunuz
              ölkədə Xidmətləri təmin etmək üçün lisenziyalaşdırılan və ya
              səlahiyyətli olan Tibb Mütəxəssisi ilə sizin aranızda əlaqə qura
              bilsin. Saytımızda Google Maps xidmətlərinə daxil olanda, siz,
              Google–nun Xidmətlər Şərtləri (
              <a href="https://developers.google.com/maps/terms">
                Terms of Service
              </a>
              ) və Məxfilik Siyasəti (
              <a href="https://policies.google.com/privacy?hl=en&amp;gl=us">
                Privacy Policy
              </a>
              ) ilə razılaşmış olursunuz.
            </li>
            <li>
              <strong>Mobil xidmətlər</strong>. Biz mobil cihazınızdan və ya
              kompüterinizdən qeyri – şəxsi məlumatları toplaya bilərik.
              Ümumiyyətlə, bu məlumatlar bizə sizin üçün ən müvafiq məlumatları
              çatdırmağa kömək etmək üçün istifadə edilir. Toplanıla və istifadə
              edilə biləcək məlumatlara dair nümunələrə sizin istifadə etdiyiniz
              cihazın və ya kompüterin növü haqqında məlumatlar və tətbiqdən
              (lərdən) necə istifadə etdiyiniz daxildir. Bundan əlavə, bizim
              tətbiq (lər) haqqında mobil cihazınıza məlumat gəldikdə, biz,
              mobil cihazınızın model proqram təminatı versiyası və cihaz
              daşıyıcısı haqqında məlumatlar qəbul edəcəyik, bu məlumatlar isə
              bizə problemləri müəyyənləşdirmək və aradan qaldırmaq və digər
              şəkildə tətbiqlərimizin performansını təkmilləşdirmək imkanı
              verir.
            </li>
            <li>
              <strong>Analitika alətləri</strong>. Biz istifadəçilərin Saytdan
              necə istifadə etdiklərini analiz etməyə kömək etmək üçün Google
              Analytics kimi alətlərdən istifadə edirik. Bu üçüncü tərəflər
              bizim adımızdan istifadə və cihaz ilə əlaqəli məlumat və
              verilənləri toplaya və analiz edə bilmək üçün xidmətlərimizdə
              cookies, API və SDK–lərdən istifadə edə bilərlər. Google
              Analytics, AppsFlyer və Mixpanel istifadəçilərin Sayta neçə
              vaxtdan bir daxil olmaları, hansı səhifələrə baxmaları və Sayta
              daxil olmazdan əvvəl hansı digər saytlardan istifadə etdikləri
              barədə məlumatları toplamaq üçün Cookies – dən istifadə edirlər.
              Biz əldə etdiyimiz məlumatlardan Saytımızı və Xidmətlərimizi
              təkmilləşdirmək üçün istifadə edirik. Google Analytics növbəti
              dəfə Sayta daxil olduğunuz vaxt sizi unikal istifadəçi kimi
              müəyyənləşdirməkdən ötrü veb brauzerinizdə davamlı Cookie yaratsa
              da, Cookie, Google–dan başqa heç kim tərəfindən istifadə edilə
              bilməz. Google–un Google Analytics tərəfindən sizin Sayta daxil
              olmağınız haqqında toplanılan məlumatlardan istifadə etməsi və
              onları paylaşması Google Analytics – in İstifadə Qaydaları və
              Google–un Məxfilik Siyasəti ilə məhdudlaşdırılır. Siz{" "}
              <a href="https://tools.google.com/dlpage/gaoptout/">
                https://tools.google.com/dlpage/gaoptout/
              </a>{" "}
              ünvanında&nbsp; mövcud olan Google Analytics Opt-out Browser
              Add-on tətbiqini yükləməklə və quraşdırmaqla məlumatlarınızın
              Google&nbsp; Analytics tərəfindən istifadə edilməsinin qarşısını
              ala bilərsiniz, o həmçinin Mixpanel –in Sayta daxil olmalarınız
              haqqında topladığı məlumatlardan istifadə etmək və onları
              paylaşmaq imkanının ünvanında{" "}
              <a href="https://mixpanel.com/terms/">
                https://mixpanel.com/terms/
              </a>
              &nbsp;yerləşən Mixpanel İstifadə Qaydaları və{" "}
              <a href="https://mixpanel.com/privacy/">
                https://mixpanel.com/privacy/
              </a>{" "}
              ünvanında yerləşən Mixpanel Məxfilik Siyasəti ilə&nbsp;
              məhdudlaşdırılması ilə də mövcuddur. Siz Saytda olan zaman bu
              keçid{" "}
              <a href="https://mixpanel.com/optout/">
                https://mixpanel.com/optout/
              </a>{" "}
              üzərində klikləməklə brauzerləriniz vasitəsilə toplanılan
              məlumatların Mixpanel – tərəfindən avtomatik saxlanılmasından
              imtina edə bilərsiniz. İmtinaları izləmək üçün Mixpanel
              cihazlarınızda olan davamlı imtina seçimləri cookie – dən istifadə
              edir. Siz həmçinin AppsFlyer – in topladığı məlumatların növünü və
              onun vebsaytına daxil olaraq{" "}
              <a href="http://www.appsflyer.com/opt-out">
                http://www.appsflyer.com/opt-out
              </a>{" "}
              və ya{" "}
              <a href="mailto:privacy@appsflyer.com">privacy@appsflyer.com</a>{" "}
              ünvanına elektron poçt məktubu göndərərək AppsFlyer – in necə
              imtina edəcəyi barədə məlumatlara rast gələ bilərsiniz.
            </li>
          </ul>
          <p>
            <strong>
              <em>Üçüncü tərəflər ilə paylaşdığınız məlumatlar </em>
            </strong>
          </p>
          <p>
            Bu Sayt Məxfilik Siyasəti yalnız Sayt vasitəsilə və Sayt ilə
            əlaqədar olaraq elektron poçt, mətn və digər elektron
            kommunikasiyalarda toplanan məlumatlara şamil olunur. Saytın
            Məxfilik Siyasəti hər hansı üçüncü tərəf tərəfindən toplanan
            məlumatlara aid deyil. Saytdakı keçidlər üzərində klikləyən zaman,
            Saytımızı tərk edə bilərsiniz. Biz digər saytların məxfilik
            siyasətləri üçün məsuliyyət daşımırıq və sizə onların məxfilik
            bəyanatlarını oxumağı tövsiyyə edirik.
          </p>
          <p>
            <strong>
              <em>Məlumatların dəyişdirilməsi</em>
            </strong>
          </p>
          <p>
            Üzvlər Sayt vasitəsilə öz məlumatlarından bəzilərini yeniləməyə
            qadir olacaqlar. Hər hansı məlumatı dəyişdirmək sorğusu həmçinin{" "}
            <a href="mailto:destek@ezgil.az">destek@ezgil.az</a>{" "}
            ünvanına təqdim edilə bilər.
          </p>
          <p>
            <strong>
              <em>Məlumatların silinməsi üzrə məhdudiyyətlər </em>
            </strong>
          </p>
          <p>
            Siz tərəfimizdən Şəxsi Məlumatlarınızın silinməsini tələb edə
            bilərsiniz, lakin bizdən bu məlumatları saxlamaq və onları silməmək
            (və ya müəyyən vaxt ərzində bu məlumatları saxlamaq, bu zaman biz
            yalnız həmin tələbləri yerinə yetirdikdən sonra sizin məlumatı
            silmək tələbinizə əməl edəcəyik) tələb oluna bilər (qanunla və ya
            digər şəkildə). Şəxsi Məlumatları silən zaman onlar aktiv verilənlər
            bazasından silinəcəkdir, lakin bizim arxivlərimizdə qalacaq və biz
            həmçinin sizin xidmətlərimizdən istifadəniz haqqında Anonim
            Məlumatları da saxlaya bilərik. Şəxsi Məlumatlarınızın bəzilərini
            üçüncü tərəflərə açıqladığımız zaman biz artıq həmin Şəxsi
            Məlumatlara daxil olmaya bilərik və açıqlamaları etdiyimiz tərəflər
            tərəfindən hər hansı belə məlumatların silinməsini və ya
            dəyişdirilməsini tələb edə bilmərik. Şəxsi Məlumatları sildikdən
            sonra biz müəyyənləşdirilməmiş məlumatları saxlaya bilərik və bu
            Sayt Məxfilik Siyasətində icazə verilən qaydada Müəyyənləşdirilməmiş
            Məlumatlardan istifadə etməyə davam edəcəyik.
          </p>
          <p>
            <strong>
              <em>
                Məlumatlarınızı təhlükəsiz şəkildə saxlamaq üçün gördüyümüz
                tədbirlər{" "}
              </em>
            </strong>
          </p>
          <p>
            Şəxsi Məlumatlarınızı icazəsiz girişə qarşı qorumağa kömək etmək
            üçün şifrələmə kimi əsaslı fiziki, elektron və idarəçilik
            təhlükəsizlik metodlarından istifadə edirik. Lakin məlumatınız olsun
            ki, İnternet və ya məlumatların saxlanılması obyekti vasitəsilə
            ötürülən heç bir məlumatın mükəmməl şəkildə təhlükəsizliyinə zəmanət
            verilə bilməz. Nəticədə, sizin Şəxsi Məlumatlarınızı qorumağa
            çalışsaq da, bizə ötürdüyünüz hər hansı məlumatın təhlükəsizliyinə
            təminat və zəmanət verə bilmərik.
          </p>
          <p>
            <strong>
              <em>Ayrıseçkilik etməmək hüququ </em>
            </strong>
          </p>
          <p>
            <strong>“KursEvi”</strong>&nbsp;sizə qarşı qanuna əsasən və ya
            bu Saytın Məxfilik Siyasətində müəyyənləşdirilən qaydada məxfilik
            hüquqlarınızın hər hansı birini icra etdiyiniz üçün ayrıseçkilik
            etməyəcəkdir.
          </p>
          <p>
            <strong>
              <em>Saytın Məxfilik Siyasətinə edilən dəyişikliklər</em>
            </strong>
          </p>
          <p>
            Biz gələcəkdə&nbsp; mütəmadi Saytın Məxfilik Siyasətinə dəyişiklik
            edə bilərik. Biz Saytın Məxfilik Siyasətinin hər hansı yenidən
            işlənmiş versiyasını bu səhifədə yayımlayacağıq. Həmin dəyişikliklər
            haqqında bildirişi qəbul etdikdən sonra xidmətlərimizdən istifadəyə
            davam etməyiniz sizin həmin dəyişikləri qəbul etməyinizi və onların
            şərtlərinə&nbsp; əməl etməyə razılaşdığınızı göstərəcəkdir. Saytdan
            istifadə etməklə, siz, Şəxsi Məlumatların və digər məlumatların
            mövcud olduğu kimi, həm də zamanla &nbsp;dəyişiklik edildiyi kimi bu
            Saytın Məxfilik Siyasətində təsvir edilən qaydada toplanması,
            istifadə edilməsi və kənarlaşdırılması ilə razılaşırsınız.
          </p>
          <p>
            <strong>“KursEvi”</strong>–un Məxfilik Siyasətində (I və II
            Bölmələr) böyük hərflər ilə verilən, burada izah edilməyən bütün
            terminlər “<strong>KursEvi ”</strong> İstifadə Qaydalarında
            müəyyənləşdirilən mənanı kəsb edəcəklər.
          </p>
          <p>
            <em>
              Məxfilik Təcrübələrimiz ilə bağlı sualınız olarsa və ya hər hansı
              bir &nbsp;pozuntu halı barədə məlumat vermək istədiyiniz halda{" "}
            </em>
            <u>
              <a href="mailto:destek@ezgil.az">destek@ezgil.az</a>
            </u>
            <em>
              {" "}
              ünvanına elektron poçt məktubu göndərərək bizim ilə əlaqə
              saxlamağınız xahiş olunur.&nbsp;{" "}
            </em>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
