import { useCallback, useEffect, useState } from "react";
import Card from "../components/Card";
import axios from "axios";
import { useTranslation } from "react-i18next";

const TrainingCategory = () => {
  const [prevNumber, setPrevNumber] = useState(0);
  const [activeNumber, setActiveNumber] = useState(1);
  const [nextNumber, setNextNumber] = useState(2);
  const [courceData, setCourseData] = useState([]);

  const{i18n}=useTranslation()

  const getCourseData = useCallback(async () => {
    await axios
      .get(`https://api.uimconsulting.com/${i18n.language}/v3/courses`, {
        crossdomain: true,
      })
      .then((res) => {
        setCourseData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getCourseData();
  }, [getCourseData]);

  return (
    <>
      <section className="training-category">
        <div className="container">
   
        </div>
      </section>
    </>
  );
};

export default TrainingCategory;
