import React from "react";

const CardLoader = () => {
  return (
    <div className="general-card-loading">
      <div className="top">
        <div className="title"></div>
      </div>
      <div className="middle"></div>
      <div className="bottom">
        <div className="info">
          <div className="title"></div>
          <div className="date"></div>
        </div>
        <p className="company-name"></p>
      </div>
    </div>
  );
};

export default CardLoader;
