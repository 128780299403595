import { Auth } from "../../utils/Auth";
// import ProfileChangePassword from "./ProfileChangePassword";
import PersonalDataEdit from "./PersonalDataEdit";
import { useContext } from "react";
const PersonalInformation = () => {
  // Global State
  const { selectVisible, setSelectVisible } = useContext(Auth);

  return (
    <div
      className="personal-information"
      onClick={() => selectVisible && setSelectVisible("")}
    >
      <PersonalDataEdit />
      {/* <ProfileChangePassword /> */}
    </div>
  );
};

export default PersonalInformation;
