import React from "react";

const AllTicketLoader = () => {
  return (
    <div className="ticket-item-loader">
      <div className="info-area"></div>
      <div className="watch-ticket"></div>
    </div>
  );
};

export default AllTicketLoader;
