import React, { useContext } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { MainContext } from "../utils/MainContext";

const CalendarModal = () => {
  // Global State
  const {
    calendarVisible,
    setCalendarVisible,
    selectRangeDate,
    setSelectRangeDate,
    handleEdit,
    setHandleEdit,
  } = useContext(MainContext);

  // Change Date
  const onChange = (nextValue) => {
    let startDate = selectRangeDate.start;
    let endDate = selectRangeDate.end;

    if (startDate === "" || endDate === "") {
      if (handleEdit === "start") {
        setSelectRangeDate({ ...selectRangeDate, start: nextValue });
      } else {
        setSelectRangeDate({ ...selectRangeDate, end: nextValue });
      }
    } else if (handleEdit === "start") {
      if (nextValue > endDate) {
        setSelectRangeDate({ start: endDate, end: nextValue });
      } else {
        setSelectRangeDate({ ...selectRangeDate, start: nextValue });
      }
    } else {
      if (nextValue > startDate) {
        setSelectRangeDate({ ...selectRangeDate, end: nextValue });
      } else {
        setSelectRangeDate({ start: nextValue, end: startDate });
      }
    }
    setCalendarVisible(false);
    setHandleEdit("");
  };

  return (
    <div
      className={calendarVisible ? "mobile-calendar-modal  active" : "mobile-calendar-modal "}
      onClick={() => {
        calendarVisible && setCalendarVisible(false);
      }}
    >
      <div
        className="mini-calendar"
        data-aos="fade-up"
        data-aos-duration="700"
        onClick={(e) => e.stopPropagation()}
      >
        <Calendar onChange={onChange} value={selectRangeDate[handleEdit]} />
      </div>
    </div>
  );
};

export default CalendarModal;
