import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

import pattern from "../../assets/img/subscribe-card-pattern.svg";
import { useTranslation } from "react-i18next";
import prev from "../../assets/img/icon/slider-prev-btn.svg";
import next from "../../assets/img/icon/slider-next-btn.svg";
import subscribeData from "../../db/subscribe";
import { useNavigate } from "react-router-dom";
const Subscribe = () => {
  // Local State
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // Language
  const { t, i18n } = useTranslation();

  // Navigate
  const navigate = useNavigate();

  // Elements
  const swiperRef = useRef(null);
  const handleSlideClick = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index, 500); // 500 ms animasyon süresi
    }
  };
  return (
    <section className="subscibe-partners">
      <div className="container">
        <div className="subscribe-content">
          <Swiper
            ref={swiperRef}
            onSwiper={setThumbsSwiper}
            loop={false}
            spaceBetween={10}
            slidesPerView={1.5}
            breakpoints={{
              100: {
                slidesPerView: 1.4,
                centeredSlides: true,
              },
              350: {
                slidesPerView: 1.9,
                centeredSlides: true,
              },
              485: {
                slidesPerView: 2.2,
                centeredSlides: true,
              },
              575: {
                slidesPerView: 2.7,
                centeredSlides: true,
              },

              650: {
                slidesPerView: 3.2,
                centeredSlides: true,
              },

              //   850: {
              centeredSlides: true,
              //   },

              992: {
                slidesPerView: 4,
                centeredSlides: false,
              },
            }}
            //   watchSlidesProgress={true}
            modules={[Navigation, Thumbs]}
            className="subscribe-card-area"
          >
            {subscribeData.map((subcribe, index) => (
              <SwiperSlide
                key={subcribe.id}
                onClick={() => handleSlideClick(index)}
              >
                <div className="subscribe-card">
                  <div className="price-info">
                    {subcribe.price === 0 ? (
                      <h3 className="caption-free">
                        {subcribe[`type_${i18n.language}`]}
                      </h3>
                    ) : (
                      <p className="price">
                        <span className="currency">AZN</span> {subcribe.price}
                      </p>
                    )}

                    <h5 className="subcaption">
                      {/* {subcribe[`type_${i18n.language}`]} plan */}
                      {t("general.annual")}
                    </h5>
                  </div>
                  <h2 className="packet-name">
                    {" "}
                    {subcribe[`type_${i18n.language}`]}
                  </h2>
                  <p className="read-more">{t("general.data_details")}</p>
                  <div className="pattern-top pattern-img">
                    <img src={pattern} alt="pattern" />
                  </div>
                  <div className="pattern-bottom pattern-img">
                    <img src={pattern} alt="pattern" />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="detail-slider"
          >
            {subscribeData.map((subscribe) => (
              <SwiperSlide key={subscribe.id}>
                <div className="info-content">
                  <h2 className="caption">
                    {subscribe[`type_${i18n.language}`]} plan
                  </h2>
                  <ul className="plan-list">
                    {subscribe.detail.map((detail) => (
                      <li className="plan-item" key={detail.id}>
                        <p className="detail">
                          {" "}
                          {detail[`type_description_${i18n.language}`]}
                        </p>
                        {detail.subdetail && (
                          <ul className="sub-list">
                            {detail.subdetail.map((subdetailDatas) => (
                              <li className="sub-item" key={subdetailDatas.id}>
                                <p>{subdetailDatas[`${i18n.language}`]}</p>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                  <div className="btn-area">
                    <button
                      className="subscribe"
                      onClick={() =>
                        navigate(
                          `/partner-invoice?plan-type=${subscribe[`type_en`]}`
                        )
                      }
                    >
                      {t("general.subuscribe")}
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="navigate-area">
              <button
                onClick={(e) =>
                  e.target.parentElement.parentElement.parentElement.children[1].click()
                }
              >
                <img src={prev} alt="prev" />{" "}
              </button>
              <p className="navigate-text">{t("general.subuscribe")}</p>
              <button
                onClick={(e) =>
                  e.target.parentElement.parentElement.parentElement.children[2].click()
                }
              >
                <img src={next} alt="next" />{" "}
              </button>
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
