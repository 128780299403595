import React, { useState } from "react";
import logo from "../../assets/img/Kurs evi logo.png";
import { useTranslation } from "react-i18next";

const InfoArea = ({ aboutus, statistic }) => {
  /* ----------------------------- Text Close Mode ---------------------------- */
  const [readMore, setReadMore] = useState(false);

  /* ------------------------------- Set Height ------------------------------- */
  const {t}=useTranslation()
  return (
    <section className="information-area ">
      <div className="container">
        {aboutus && (
          <div className="head">
            <h5 className="title">{aboutus.name}</h5>
            <div className="row">
              <div className="info">
                <div
                  className={readMore ? " text large-text" : "text"}
                  dangerouslySetInnerHTML={{ __html: aboutus.text }}
                />

                <span
                  className="read-more "
                  onClick={() => {
                    setReadMore(!readMore);
                  }}
                >
                  {readMore ? t("general.hide") : t("general.read_more")}
                </span>
              </div>
              <div className="logo">
                <img src={logo} alt="logo" />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="body">
        {statistic && (
          <>
            <div className="line-area">
              <span className="line"></span>
            </div>
            <div className="container">
              <div className="static">
                <div className="row">
                  <div className="static-card">
                    <span className="number">{statistic.conferences}+</span>
                    <h4 className="title">{t("home.conferance")}</h4>
                  </div>
                  <div className="divider"></div>
                  <div className="static-card">
                    <span className="number">{statistic.courses}+</span>
                    <h4 className="title">{t("home.coruse")}</h4>
                  </div>
                  <div className="divider"></div>
                  <div className="static-card">
                    <span className="number">{statistic.partners}+</span>
                    <h4 className="title">{t("home.partners")}</h4>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default InfoArea;
