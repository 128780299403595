import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
/* --------------------------------- Router --------------------------------- */
import {  BrowserRouter } from 'react-router-dom';
/* -------------------------------- My Style -------------------------------- */
import "./assets/sass/main.scss"

// Translation
import './utils/i18n';

// Aos
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

