const subscribeData = [
  {
    id: 1,
    price: 0,
    type_az: "Sınaq",
    type_en: "Free",
    type_ru: "Бесплатно",
    detail: [
      {
        id: 57,
        type_description_az: "İl əzrində 2 tədbir",
        type_description_en: "2 events per year",
        type_description_ru: "2 мероприятия в год",
      },
      {
        id: 5,
        type_description_az:
          "Tədbirin portalda qeydiyyatı, hesabatın verilməsi, balların yüklənməsi.",
        type_description_en:
          "Registration of the event on the portal, reporting, uploading scores.",
        type_description_ru:
          "Регистрация мероприятия на портале, отчетность, выгрузка результатов.",
      },
      {
        id: 6,
        type_description_az: "Tədbirin təfərrüatları üzrə funksiyalar: ",
        type_description_en: "Features on event details",
        type_description_ru: "Особенности в деталях мероприятия:",
        subdetail: [
          {
            id: 7,
            az: "agendanın yerləşdirilməsi",
            en: "placement of the agenda",
            ru: "размещение повестки дня",
          },
          {
            id: 8,
            az: "məruzəçilər/spikerlərin fotoları və məlumatları",
            en: "photos and information of presenters/speakers",
            ru: "фотографии и информация докладчиков/спикеров",
          },
          {
            id: 9,
            az: "paylaşım üçün fərdi tədbir linki",
            en: "individual event link to share",
            ru: "ссылка на индивидуальное мероприятие, которой можно поделиться",
          },
          {
            id: 10,
            az: "iştirakçıların tədbirə qeydiyyatı",
            en: "registration of participants to the event",
            ru: "регистрация участников на мероприятие",
          },
          {
            id: 11,
            az: "tədbirə dair canlı statistika",
            en: "live event statistics",
            ru: "живая статистика мероприятий",
          },
          {
            id: 12,
            az: "qeydiyyatdan keçmiş iştirakçılara e-biletin göndərilməsi",
            en: "sending e-tickets to registered participants",
            ru: "рассылка электронных билетов зарегистрированным участникам ",
          },
          {
            id: 13,
            az: "iştirakçılara e-sertifikatların avtomatik göndərilməsi ",
            en: "automatic sending of e-certificates to participants. ",
            ru: "автоматическая отправка электронных сертификатов участникам",
          },
          {
            id: 14,
            az: "iştirakçılara dair məlumat bazasının yaradılması ",
            en: "creating a database of participants",
            ru: "создание базы данных участников  ",
          },
          {
            id: 15,
            az: "ödənişli tədbirlərin qeydiyyatı və maliyyə idarəetməsi",
            en: "registration of paid events and financial management. ",
            ru: "регистрация платных мероприятий и управление финансами",
          },
        ],
      },
      {
        id: 16,
        type_description_az:
          "Tədbirin MD Training sosial media səhifələrində paylaşımı ",
        type_description_en:
          "Sharing the event on MD Training social media pages",
        type_description_ru:
          "Расскажите о мероприятии на страницах MD Training в социальных сетях",
      },
      {
        id: 17,
        type_description_az:
          "Tədbirin MD Training mobil tətbiqində avtomatik yerləşdirilməsi",
        type_description_en:
          "Automatic placement of the event in the MD Training mobile application",
        type_description_ru:
          "Автоматическое размещение мероприятия в мобильном приложении MD Training",
      },
      {
        id: 64,
        type_description_az: "Ödənişli tədbirlər zamanı KursEvi portalı vasitəsilə iştirakçıların qeydiyyatı və ödənişlərinin qəbulu və idarə edilməsinə görə tutulmalar abuna qiymətlərinə daxil deyil. ",
        type_description_en: "Fees for registration and payment of participants through the KursEvi portal during paid events are not included in the subscription price.",
        type_description_ru: "Плата за регистрацию и оплату участников через портал КурсЭви во время платных мероприятий не включена в стоимость подписки.",
      },
    ],
  },
  {
    id: 2,
    price: 100,
    type_az: "Baza",
    type_en: "Base",
    type_ru: "База",
    detail: [
      {
        id: 58,
        type_description_az: "İl əzrində 5 tədbir",
        type_description_en: "5 events per year",
        type_description_ru: "5 мероприятия в год",
      },
      {
        id: 18,
        type_description_az:
          "Tədbirin portalda qeydiyyatı, hesabatın verilməsi, balların yüklənməsi.",
        type_description_en:
          "Registration of the event on the portal, reporting, uploading scores.",
        type_description_ru:
          "Регистрация мероприятия на портале, отчетность, выгрузка результатов.",
      },
      {
        id: 19,
        type_description_az: "Tədbirin təfərrüatları üzrə funksiyalar: ",
        type_description_en: "Features on event details",
        type_description_ru: "Особенности в деталях мероприятия:",
        subdetail: [
          {
            id: 20,
            az: "agendanın yerləşdirilməsi",
            en: "placement of the agenda",
            ru: "размещение повестки дня",
          },
          {
            id: 21,
            az: "məruzəçilər/spikerlərin fotoları və məlumatları",
            en: "photos and information of presenters/speakers",
            ru: "фотографии и информация докладчиков/спикеров",
          },
          {
            id: 22,
            az: "paylaşım üçün fərdi tədbir linki",
            en: "individual event link to share",
            ru: "ссылка на индивидуальное мероприятие, которой можно поделиться",
          },
          {
            id: 23,
            az: "iştirakçıların tədbirə qeydiyyatı",
            en: "registration of participants to the event",
            ru: "регистрация участников на мероприятие",
          },
          {
            id: 24,
            az: "tədbirə dair canlı statistika",
            en: "live event statistics",
            ru: "живая статистика мероприятий",
          },
          {
            id: 25,
            az: "qeydiyyatdan keçmiş iştirakçılara e-biletin göndərilməsi",
            en: "sending e-tickets to registered participants",
            ru: "рассылка электронных билетов зарегистрированным участникам ",
          },
          {
            id: 26,
            az: "iştirakçılara e-sertifikatların avtomatik göndərilməsi ",
            en: "automatic sending of e-certificates to participants. ",
            ru: "автоматическая отправка электронных сертификатов участникам",
          },
          {
            id: 27,
            az: "iştirakçılara dair məlumat bazasının yaradılması ",
            en: "creating a database of participants",
            ru: "создание базы данных участников  ",
          },
          {
            id: 28,
            az: "ödənişli tədbirlərin qeydiyyatı və maliyyə idarəetməsi",
            en: "registration of paid events and financial management. ",
            ru: "регистрация платных мероприятий и управление финансами",
          },
        ],
      },
      {
        id: 29,
        type_description_az:
          "Tədbirin MD Training sosial media səhifələrində paylaşımı ",
        type_description_en:
          "Sharing the event on MD Training social media pages",
        type_description_ru:
          "Расскажите о мероприятии на страницах MD Training в социальных сетях",
      },
      {
        id: 30,
        type_description_az:
          "Tədbirin MD Training mobil tətbiqində avtomatik yerləşdirilməsi",
        type_description_en:
          "Automatic placement of the event in the MD Training mobile application",
        type_description_ru:
          "Автоматическое размещение мероприятия в мобильном приложении MD Training",
      },
      {
        id: 63,
        type_description_az: "Ödənişli tədbirlər zamanı KursEvi portalı vasitəsilə iştirakçıların qeydiyyatı və ödənişlərinin qəbulu və idarə edilməsinə görə tutulmalar abuna qiymətlərinə daxil deyil. ",
        type_description_en: "Fees for registration and payment of participants through the KursEvi portal during paid events are not included in the subscription price.",
        type_description_ru: "Плата за регистрацию и оплату участников через портал КурсЭви во время платных мероприятий не включена в стоимость подписки.",
      },
    ],
  },
  {
    id: 3,
    price: 150,
    type_az: "Peşəkar",
    type_en: "Professional",
    type_ru: "Профессиональный",
    detail: [
      {
        id: 59,
        type_description_az: "İl əzrində 10 tədbir",
        type_description_en: "10 events per year",
        type_description_ru: "10 мероприятия в год",
      },
      {
        id: 31,
        type_description_az:
          "Tədbirin portalda qeydiyyatı, hesabatın verilməsi, balların yüklənməsi.",
        type_description_en:
          "Registration of the event on the portal, reporting, uploading scores.",
        type_description_ru:
          "Регистрация мероприятия на портале, отчетность, выгрузка результатов.",
      },
      {
        id: 32,
        type_description_az: "Tədbirin təfərrüatları üzrə funksiyalar: ",
        type_description_en: "Features on event details",
        type_description_ru: "Особенности в деталях мероприятия:",
        subdetail: [
          {
            id: 33,
            az: "agendanın yerləşdirilməsi",
            en: "placement of the agenda",
            ru: "размещение повестки дня",
          },
          {
            id: 34,
            az: "məruzəçilər/spikerlərin fotoları və məlumatları",
            en: "photos and information of presenters/speakers",
            ru: "фотографии и информация докладчиков/спикеров",
          },
          {
            id: 35,
            az: "paylaşım üçün fərdi tədbir linki",
            en: "individual event link to share",
            ru: "ссылка на индивидуальное мероприятие, которой можно поделиться",
          },
          {
            id: 36,
            az: "iştirakçıların tədbirə qeydiyyatı",
            en: "registration of participants to the event",
            ru: "регистрация участников на мероприятие",
          },
          {
            id: 37,
            az: "tədbirə dair canlı statistika",
            en: "live event statistics",
            ru: "живая статистика мероприятий",
          },
          {
            id: 38,
            az: "qeydiyyatdan keçmiş iştirakçılara e-biletin göndərilməsi",
            en: "sending e-tickets to registered participants",
            ru: "рассылка электронных билетов зарегистрированным участникам ",
          },
          {
            id: 39,
            az: "iştirakçılara e-sertifikatların avtomatik göndərilməsi ",
            en: "automatic sending of e-certificates to participants. ",
            ru: "автоматическая отправка электронных сертификатов участникам",
          },
          {
            id: 40,
            az: "iştirakçılara dair məlumat bazasının yaradılması ",
            en: "creating a database of participants",
            ru: "создание базы данных участников  ",
          },
          {
            id: 41,
            az: "ödənişli tədbirlərin qeydiyyatı və maliyyə idarəetməsi",
            en: "registration of paid events and financial management. ",
            ru: "регистрация платных мероприятий и управление финансами",
          },
        ],
      },
      {
        id: 42,
        type_description_az:
          "Tədbirin MD Training sosial media səhifələrində paylaşımı ",
        type_description_en:
          "Sharing the event on MD Training social media pages",
        type_description_ru:
          "Расскажите о мероприятии на страницах MD Training в социальных сетях",
      },
      {
        id: 43,
        type_description_az:
          "Tədbirin MD Training mobil tətbiqində avtomatik yerləşdirilməsi",
        type_description_en:
          "Automatic placement of the event in the MD Training mobile application",
        type_description_ru:
          "Автоматическое размещение мероприятия в мобильном приложении MD Training",
      },
      {
        id: 62,
        type_description_az: "Ödənişli tədbirlər zamanı KursEvi portalı vasitəsilə iştirakçıların qeydiyyatı və ödənişlərinin qəbulu və idarə edilməsinə görə tutulmalar abuna qiymətlərinə daxil deyil. ",
        type_description_en: "Fees for registration and payment of participants through the KursEvi portal during paid events are not included in the subscription price.",
        type_description_ru: "Плата за регистрацию и оплату участников через портал КурсЭви во время платных мероприятий не включена в стоимость подписки.",
      },
    ],
  },
  {
    id: 4,
    price: 250,
    type_az: "Biznes",
    type_en: "Business",
    type_ru: "Бизнес",
    detail: [
      {
        id: 60,
        type_description_az: "İl ərzində limitsiz tədbir yaratma imkanı",
        type_description_en: "Unlimited event creation throughout the year",
        type_description_ru: "Неограниченное создание событий в течение года",
      },
      {
        id: 44,
        type_description_az:
          "Tədbirin portalda qeydiyyatı, hesabatın verilməsi, balların yüklənməsi.",
        type_description_en:
          "Registration of the event on the portal, reporting, uploading scores.",
        type_description_ru:
          "Регистрация мероприятия на портале, отчетность, выгрузка результатов.",
      },
      {
        id: 45,
        type_description_az: "Tədbirin təfərrüatları üzrə funksiyalar: ",
        type_description_en: "Features on event details",
        type_description_ru: "Особенности в деталях мероприятия:",
        subdetail: [
          {
            id: 46,
            az: "agendanın yerləşdirilməsi",
            en: "placement of the agenda",
            ru: "размещение повестки дня",
          },
          {
            id: 47,
            az: "məruzəçilər/spikerlərin fotoları və məlumatları",
            en: "photos and information of presenters/speakers",
            ru: "фотографии и информация докладчиков/спикеров",
          },
          {
            id: 48,
            az: "paylaşım üçün fərdi tədbir linki",
            en: "individual event link to share",
            ru: "ссылка на индивидуальное мероприятие, которой можно поделиться",
          },
          {
            id: 49,
            az: "iştirakçıların tədbirə qeydiyyatı",
            en: "registration of participants to the event",
            ru: "регистрация участников на мероприятие",
          },
          {
            id: 50,
            az: "tədbirə dair canlı statistika",
            en: "live event statistics",
            ru: "живая статистика мероприятий",
          },
          {
            id: 51,
            az: "qeydiyyatdan keçmiş iştirakçılara e-biletin göndərilməsi",
            en: "sending e-tickets to registered participants",
            ru: "рассылка электронных билетов зарегистрированным участникам ",
          },
          {
            id: 52,
            az: "iştirakçılara e-sertifikatların avtomatik göndərilməsi ",
            en: "automatic sending of e-certificates to participants. ",
            ru: "автоматическая отправка электронных сертификатов участникам",
          },
          {
            id: 53,
            az: "iştirakçılara dair məlumat bazasının yaradılması ",
            en: "creating a database of participants",
            ru: "создание базы данных участников  ",
          },
          {
            id: 54,
            az: "ödənişli tədbirlərin qeydiyyatı və maliyyə idarəetməsi",
            en: "registration of paid events and financial management. ",
            ru: "регистрация платных мероприятий и управление финансами",
          },
        ],
      },
      {
        id: 55,
        type_description_az:
          "Tədbirin MD Training sosial media səhifələrində paylaşımı ",
        type_description_en:
          "Sharing the event on MD Training social media pages",
        type_description_ru:
          "Расскажите о мероприятии на страницах MD Training в социальных сетях",
      },
      {
        id: 56,
        type_description_az:
          "Tədbirin MD Training mobil tətbiqində avtomatik yerləşdirilməsi",
        type_description_en:
          "Automatic placement of the event in the MD Training mobile application",
        type_description_ru:
          "Автоматическое размещение мероприятия в мобильном приложении MD Training",
      },
      {
        id: 61,
        type_description_az: "Ödənişli tədbirlər zamanı KursEvi portalı vasitəsilə iştirakçıların qeydiyyatı və ödənişlərinin qəbulu və idarə edilməsinə görə tutulmalar abuna qiymətlərinə daxil deyil. ",
        type_description_en: "Fees for registration and payment of participants through the KursEvi portal during paid events are not included in the subscription price.",
        type_description_ru: "Плата за регистрацию и оплату участников через портал КурсЭви во время платных мероприятий не включена в стоимость подписки.",
      },
    ],
  },
];
export default subscribeData;
