import React, { useCallback, useContext, useEffect, useState } from "react";
import { Auth } from "../../utils/Auth";
import { useTranslation } from "react-i18next";
import axios from "axios";

// Logo
import logo from "../../assets/img/kurseviFramePhoto.svg";
import pdf from "../../assets/img/icon/document-download.svg";
import { useNavigate } from "react-router-dom";
import AllTicketLoader from "../confrance-cource-detail/AllTicketLoader";

const Certificate = () => {
  // Global Data
  const { token } = useContext(Auth);

  //   Local State
  const [type, setType] = useState("conferences");
  const [dataList, setDataList] = useState(["loading"]);
  const [selectAction, setSelectAction] = useState(null);

  // Language
  const { t, i18n } = useTranslation();

  // React Router DOm
  const navigate = useNavigate();

  //   Get Request For Registeried Courses
  const getMyCoursesConference = useCallback(async () => {
    setDataList(["loading"]);
    await axios
      .get(
        `https://api.uimconsulting.com/${i18n.language}/v3/${type}/participant?token=${token}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.certificate !== "None"
        );
        setDataList(filteredData);
      })
      .catch((err) => {
        if (err) {
          setDataList(["loading"]);
          navigate("/error");
        }
      });
  }, [i18n.language, navigate, token, type]);

  // Render Data
  useEffect(() => {
    setDataList([]);
    if (token && i18n.language) {
      getMyCoursesConference();
    }
  }, [getMyCoursesConference, i18n.language, token, type]);
  console.log(type);
  function getCertificate(data) {
    axios
      .get(
        `https://api.uimconsulting.com/${
          i18n.language
        }/v3/users/certificates?token=${token}&type=${
          type === "courses" ? "1" : "2"
        }&typeid=${type === "courses" ? data.course_id : data.conference_id} `,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.data instanceof Blob) {
          // Create a URL for the Blob object
          const url = URL.createObjectURL(response.data);

          // Create a temporary link element
          const link = document.createElement("a");
          link.href = url;
          link.download = `${ type === "courses" ? data.coursename :   data.conferencename}.pdf`; // Set the filename for download

          // Trigger the download by clicking the link
          link.click();

          // Cleanup: Release the Blob URL
          URL.revokeObjectURL(url);
        } else {
          console.error("The response data is not a Blob");
        }
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  }

  return (
    <>
      <section className="all-ticket">
        <ul className="type-tab">
          <li
            className={
              type === "conferences" ? "type-tab-item active" : "type-tab-item"
            }
            onClick={() => {
              if (type !== "conferences") {
                setType("conferences");
                setDataList([]);
              }
            }}
          >
            {t("header.conference")}
          </li>
          <li
            className={
              type !== "conferences" ? "type-tab-item active" : "type-tab-item"
            }
            onClick={() => {
              if (type === "conferences") {
                setType("courses");
                setDataList([]);
              }
            }}
          >
            {t("header.courses")}
          </li>
        </ul>
        <div className="ticket-list">
          {dataList[0] === "loading" ? (
            <AllTicketLoader />
          ) : dataList.length === 0 ? (
            <div className="alert"> {t("profil.history_empty")}</div>
          ) : (
            dataList.map((data) => (
              <div
                className="ticket-item"
                key={
                  type === "conferences" ? data.conference_id : data.course_id
                }
              >
                <div
                  className="info-area"
                  onClick={() =>
                    navigate(
                      `/general-detail/${
                        type === "courses" ? "courses" : "conferences"
                      }/${
                        type === "courses" ? data.course_id : data.conference_id
                      }`
                    )
                  }
                >
                  <p className="action-name">
                    {type === "conferences"
                      ? data.conferencename
                      : data.coursename}
                  </p>
                </div>
                <button
                  className="watch-ticket"
                  onClick={() => {
                    setSelectAction(data);
                    getCertificate(data);
                  }}
                >
                  {t("general.download")} <img src={pdf} alt="document" />
                </button>
              </div>
            ))
          )}
        </div>
      </section>
    </>
  );
};

export default Certificate;
