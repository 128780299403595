import { Link } from "react-router-dom";
import notFoundImg from "../assets/img/404.png";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
      <section className="not-found-page">
        <div className="container">
          <div className="bg-img">
            <img src={notFoundImg} alt="404" />
            <div className="btn-area">
              <Link to="/">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.23047 12.4698L20.6177 12.4698C22.5339 12.4698 24.0873 14.0232 24.0873 15.9394L24.0873 17.2121C24.0873 19.1283 22.5339 20.6816 20.6177 20.6816L8.57603 20.6816"
                    stroke="#001D48"
                    strokeWidth="1.73629"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.27344 8.81974C6.84814 10.245 6.04903 11.0441 4.62373 12.4694L8.27344 16.1191"
                    stroke="#001D48"
                    strokeWidth="1.73629"
                    strokeLinejoin="round"
                  />
                </svg>
               {t("general.not_found_back")}
              </Link>
            </div>
          </div>
        </div>
      </section>
  );
};

export default NotFoundPage;
