/* ---------------------------------- React --------------------------------- */
import { useContext } from "react";

/* ---------------------------- React Router Dom ---------------------------- */
import { Link, NavLink, useNavigate } from "react-router-dom";

/* ------------------------------- Components ------------------------------- */
import SingIn from "./SingIn";
import SingUp from "./SingUp";
import ForgetPassword from "./ForgetPassword";
import ChangePassword from "./ChangePassword";
import Otp from "./Otp";
import RegisterConfirm from "./RegisterConfirm";

/* --------------------------------- Context -------------------------------- */
import { Auth } from "../utils/Auth";

// Toastie
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* --------------------------------- Images --------------------------------- */
import logo from "../assets/img/Kurs evi logo.svg";
import user from "../assets/img/icon/user.svg";
import { useTranslation } from "react-i18next";
import MobileSideBar from "./MobileSideBar";
import { MainContext } from "../utils/MainContext";

const Header = () => {
  //  Global State
  const { token, form, setForm, formArea, setFormArea,profilData } = useContext(Auth);
  const { sideBar, setSideBar } = useContext(MainContext);

  // React Router Dom
  const navigate = useNavigate();

  // Languge
  const { t } = useTranslation();

  return (
    <header className="header">
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
      <div className="container">
        <div className="row">
          <div className="mobile-menu" onClick={() => setSideBar(true)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 7H21"
                stroke="#292D32"
                strokeWidth="1.82766"
                strokeLinecap="round"
              />
              <path
                d="M3 12H21"
                stroke="#292D32"
                strokeWidth="1.82766"
                strokeLinecap="round"
              />
              <path
                d="M3 17H21"
                stroke="#292D32"
                strokeWidth="1.82766"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <Link to="/" className="logo">
            <img src={logo} alt="logo" />
          </Link>
          <nav className="nav-bar">
            <ul className="nav-list">
              <li className="nav-items">
                <NavLink to="/">{t("header.home")}</NavLink>
              </li>
              <li className="nav-items">
                <NavLink to="/calendar">{t("header.calendar")}</NavLink>
              </li>
              <li className="nav-items">
                <NavLink to="/conference">{t("header.conference")}</NavLink>
              </li>
              <li className="nav-items">
                <NavLink to="/courses">{t("header.courses")}</NavLink>
              </li>
              <li className="nav-items">
                <NavLink to="/partners">{t("header.partners")}</NavLink>
              </li>
              <li className="nav-items">
                <NavLink to="/innovatons"> {t("header.innovatons")}</NavLink>
              </li>
              <li className="nav-items">
                <NavLink to="/contact">{t("header.contact")}</NavLink>
              </li>
            </ul>
          </nav>
          <div className="user-area">
            {token === "" ? (
              <div className="login" onClick={() => setFormArea(true)}>
                <div className="login-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.89844 7.56023C9.20844 3.96023 11.0584 2.49023 15.1084 2.49023H15.2384C19.7084 2.49023 21.4984 4.28023 21.4984 8.75023V15.2702C21.4984 19.7402 19.7084 21.5302 15.2384 21.5302H15.1084C11.0884 21.5302 9.23844 20.0802 8.90844 16.5402"
                      stroke="#0046AC"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 12H14.88"
                      stroke="#0046AC"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.6484 8.6499L15.9984 11.9999L12.6484 15.3499"
                      stroke="#0046AC"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {t("header.login")}
              </div>
            ) : (
              <div className="profile" onClick={() => navigate("/profile")}>
                <h2 className="user-name">{ profilData &&   profilData.fullname}</h2>
                <div className="icon">
                  <img src={user} alt="user" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {formArea && (
        <div className="form-area"    onClick={() => {
          setFormArea(false);
          setSideBar(false);
          setForm("sing in");
        }} >
          {form === "sing in" || form === "sing up" ? (
            <div
              className="form-box"
              data-aos="fade-up"
              data-aos-duration="900"
              onClick={(e)=>e.stopPropagation()}
            >
              <div className="head" onClick={(e)=>e.stopPropagation()}>
                <h5
                  className={form === "sing in" ? "title active" : "title"}
                  onClick={() => setForm("sing in")}
                >
                  {t("header.login")}
                </h5>
                <h5
                  className={form === "sing up" ? "title active" : "title"}
                  onClick={() => setForm("sing up")}
                >
                  {t("header.register")}
                </h5>
              </div>
              <div className="body" onClick={(e)=>e.stopPropagation()}>
                <SingIn data={form} />
                <SingUp data={form} />
              </div>
            </div>
          ) : null}
          {form === "forgot" && (
            <div
              className="form-box forgot"
              data-aos="fade-up"
              data-aos-duration="900"
              onClick={(e)=>e.stopPropagation()}
            >
              <ForgetPassword />
            </div>
          )}
          {form === "otp" && (
            <div
              className="form-box"
              data-aos="fade-up"
              data-aos-duration="900"
              onClick={(e)=>e.stopPropagation()}
            >
              <Otp />
            </div>
          )}
          {form === "register-confirm" && (
            <div
              className="form-box"
              data-aos="fade-up"
              data-aos-duration="900"
              onClick={(e)=>e.stopPropagation()}
            >
              <RegisterConfirm />
            </div>
          )}
          {form === "change password" && (
            <div
              className="form-box"
              data-aos="fade-up"
              data-aos-duration="900"
              onClick={(e)=>e.stopPropagation()}
            >
              <ChangePassword />
            </div>
          )}
        </div>
      )}
      <MobileSideBar />
      <div
        className={ sideBar ? "overlay isOpen" : "overlay"}
        onClick={() => {
          setSideBar(false);
        }}
      ></div>
    </header>
  );
};

export default Header;
