import { useTranslation } from "react-i18next";
import conference from "../../assets/img/bannerImg/Conference.png"
import counseling from "../../assets/img/bannerImg/counseling.png"
const HomeBanner = () => {
  const {t}=useTranslation()
  return (
    <section className="home-banner">
      <div className="container">
        <div className="row">
          <div className="banner-info">
            <h3 className="banner-caption">
             {t("home.banner_text")}
            </h3>
            {/* <div className="banner-text">
              Lorem impus çapın sadəcə saxta mətnidir Lorem impus çapın sadə
            </div> */}
          </div>
          <div className="banner-images">
            <div className="baner-img-box">
              <img src={conference} alt="conference" />
            </div>
            <div className="baner-img-box">
              <img src={counseling} alt="counseling" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
