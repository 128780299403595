import { useContext, useState } from "react";
import logo from "../assets/img/Kurs evi logo.svg";
import { MainContext } from "../utils/MainContext";
import { Auth } from "../utils/Auth";

const Ticket = ({
  data,
  participationType,
  type,
  eventStartDate,
  eventLocation,
  eventPrice,
  eventHost,
  eventPhone,
  eventMail,
  eventName,
}) => {
  // Global State
  const { setTicketVisible } = useContext(MainContext);
  const { profilData } = useContext(Auth);

  // Local State
  const [isLoaded, setIsLoaded] = useState(false);
  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    // Return the date in "June 10 - 2024" format
    return formattedDate.replace(",", " -");
  };

  return (
    <section
      className="ticket"
      onClick={() => setTicketVisible(false)}
      // ref={content}
    >
      <div
        className="ticket-body"
        data-aos="fade-up"
        data-aos-duration="900"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="ticket-review-data">
          <div className="logo">
            <img src={logo} alt="kursevi" />
          </div>
          {eventName && <h2 className="event-name">{eventName}</h2>}

          <h2 className="personal-fullname">{profilData.fullname}</h2>

          {participationType && (
            <p className="general-info">
              <span className="label">İştirak Növü:</span>
              {participationType}
            </p>
          )}
          {eventPrice && (
            <p className="general-info pay-info-ticket">
              <span className="label">Ödəniş edilib:</span>
              <span className="price"> {eventPrice.price}</span>{" "}
              <span className="money-type"> AZN</span>
            </p>
          )}

          {eventHost && (
            <p className="general-info">
              <span className="label">Təşkilatçı:</span>
              {eventHost}
            </p>
          )}

          <p className="general-info">
            <span className="label">Mobil nömrə:</span>
            {profilData.phone}
          </p>
          {(eventMail || eventPhone) && (
            <p className="general-info support">
              <span className="label">Texniki dəstək:</span>
              {eventPhone} -{eventMail}
            </p>
          )}
        </div>

        <div className="qr-body">
          <div className="qr-photo">
            {!isLoaded && <div className="placeholder"></div>}
            <img
              src={`${process.env.REACT_APP_IMG}/uploads/${
                type === "courses" ? "courses" : "conferences"
              }/qr/${data.participant.signcode}.png`}
              alt="qr Code"
              onLoad={handleImageLoad}
              // style={isLoaded ? {} : { display: "none" }}
            />
          </div>
          <div className="event-main-info">
            {eventStartDate && (
              <p className="general-info ">
                <span className="label">Tarix:</span>
                {formatDate(eventStartDate)}
              </p>
            )}
            {eventStartDate && (
              <p className="general-info ">
                <span className="label">Saat:</span>
                {eventStartDate.split(" ")[1].slice(0, 5)}
              </p>
            )}

            {eventLocation && (
              <p className="general-info ">
                <span className="label">Yer:</span>
                {eventLocation}
              </p>
            )}
          </div>
        </div>
        <button className="no-print" onClick={() => window.print()}>
          Print/PDF
        </button>
        {/* <button className="no-pdf" onClick={handleDownloadPDF}>
          pdf
        </button> */}
      </div>
    </section>
  );
};

export default Ticket;
