import React from 'react'
import { useTranslation } from 'react-i18next'

const MainInfo = ({data}) => {
  const{t}=useTranslation()
  return (
    <section className="training-detail-timing-info">
    <div className="container">
      <div className="row">
        <div className="info-card">
          <div className="icon">
            <svg
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M66.0078 36C66.0078 52.56 52.5678 66 36.0078 66C19.4478 66 6.00781 52.56 6.00781 36C6.00781 19.44 19.4478 6 36.0078 6C52.5678 6 66.0078 19.44 66.0078 36Z"
                stroke="white"
                strokeWidth="4.54764"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M47.1306 45.5403L37.8306 39.9903C36.2106 39.0303 34.8906 36.7203 34.8906 34.8303V22.5303"
                stroke="white"
                strokeWidth="4.54764"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="card-text">
            <p className="text">{data.startdate.split(" ")[0].split("-").reverse().join("-")}/{data.startdate.split(" ")[1].slice(0,5)}</p>
            <p className="text">{data.enddate.split(" ")[0].split("-").reverse().join("-")}/{data.enddate.split(" ")[1].slice(0,5)}</p>
          </div>
        </div>
        <div className="info-card">
          <div className="icon">
            <svg
              width="73"
              height="72"
              viewBox="0 0 73 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36.3131 40.2893C41.4825 40.2893 45.6731 36.0987 45.6731 30.9293C45.6731 25.76 41.4825 21.5693 36.3131 21.5693C31.1437 21.5693 26.9531 25.76 26.9531 30.9293C26.9531 36.0987 31.1437 40.2893 36.3131 40.2893Z"
                stroke="white"
                strokeWidth="4.54764"
              />
              <path
                d="M11.1697 25.4695C17.0797 -0.510482 55.5697 -0.480481 61.4497 25.4995C64.8997 40.7395 55.4197 53.6395 47.1097 61.6195C41.0797 67.4395 31.5397 67.4395 25.4797 61.6195C17.1997 53.6395 7.71967 40.7095 11.1697 25.4695Z"
                stroke="white"
                strokeWidth="4.54764"
              />
            </svg>
          </div>
          <div className="card-text">
            <p className="text">{data.region}/{data.location}</p>
          </div>
        </div>
        <div className="info-card">
          <div className="icon">
            <svg
              width="73"
              height="72"
              viewBox="0 0 73 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M54.4748 21.481C54.2948 21.451 54.0848 21.451 53.9048 21.481C49.7648 21.331 46.4648 17.941 46.4648 13.741C46.4648 9.45098 49.9148 6.00098 54.2048 6.00098C58.4948 6.00098 61.9448 9.48098 61.9448 13.741C61.9148 17.941 58.6148 21.331 54.4748 21.481Z"
                stroke="white"
                strokeWidth="4.54764"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M51.3869 43.321C55.4969 44.011 60.0269 43.291 63.2069 41.161C67.4369 38.341 67.4369 33.721 63.2069 30.901C59.9969 28.771 55.4069 28.051 51.2969 28.771"
                stroke="white"
                strokeWidth="4.54764"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.3841 21.481C18.5641 21.451 18.7741 21.451 18.9541 21.481C23.0941 21.331 26.3941 17.941 26.3941 13.741C26.3941 9.45098 22.9441 6.00098 18.6541 6.00098C14.3641 6.00098 10.9141 9.48098 10.9141 13.741C10.9441 17.941 14.2441 21.331 18.3841 21.481Z"
                stroke="white"
                strokeWidth="4.54764"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.473 43.321C17.363 44.011 12.833 43.291 9.65297 41.161C5.42297 38.341 5.42297 33.721 9.65297 30.901C12.863 28.771 17.453 28.051 21.563 28.771"
                stroke="white"
                strokeWidth="4.54764"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M36.4709 43.8902C36.2909 43.8602 36.0809 43.8602 35.9009 43.8902C31.7609 43.7402 28.4609 40.3502 28.4609 36.1502C28.4609 31.8602 31.9109 28.4102 36.2009 28.4102C40.4909 28.4102 43.9409 31.8902 43.9409 36.1502C43.9109 40.3502 40.6109 43.7702 36.4709 43.8902Z"
                stroke="white"
                strokeWidth="4.54764"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M27.7428 53.3401C23.5128 56.1601 23.5128 60.7801 27.7428 63.6001C32.5428 66.8101 40.4028 66.8101 45.2028 63.6001C49.4328 60.7801 49.4328 56.1601 45.2028 53.3401C40.4328 50.1601 32.5428 50.1601 27.7428 53.3401Z"
                stroke="white"
                strokeWidth="4.54764"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="card-text">
            <p className="text">{t("detail_general_info.total_count")}  :{" "}{data.participants_num}

            </p>
            <p className="text">{t("detail_general_info.remaining_count")} : {" "}{data.regnum} </p>
          </div>
        </div>
        <div className="info-card">
          <div className="icon">
            <svg
              width="73"
              height="72"
              viewBox="0 0 73 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M50.979 26.8799H21.0391"
                stroke="white"
                strokeWidth="4.54764"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M36.0156 21.8398V26.8798"
                stroke="white"
                strokeWidth="4.54764"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M43.5117 26.8193C43.5117 39.7193 33.4317 50.1592 21.0117 50.1592"
                stroke="white"
                strokeWidth="4.54764"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M51.0093 50.1599C45.6093 50.1599 40.8094 47.28 37.3594 42.75"
                stroke="white"
                strokeWidth="4.54764"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M36.0156 66C52.5842 66 66.0156 52.5685 66.0156 36C66.0156 19.4315 52.5842 6 36.0156 6C19.4471 6 6.01562 19.4315 6.01562 36C6.01562 52.5685 19.4471 66 36.0156 66Z"
                stroke="white"
                strokeWidth="4.54764"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="card-text">
            <p className="text">{data.language}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default MainInfo