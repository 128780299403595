import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// Dil değişikliklerini localStorage'a kaydetmek için callback fonksiyonu
const saveLanguage = (lng) => {
  localStorage.setItem("i18nextLng", lng);
};

// i18next konfigurasyonu
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    // İlk yüklendiğinde localStorage'daki dili kullan, yoksa default 'ru' kullan
    lng: localStorage.getItem("i18nextLng") || "az",
    // Dil değişikliklerini yakalamak için callback fonksiyonunu tanımla
    react: {
      useSuspense: false,
    },
  });

// Dil değişikliklerini dinleyerek localStorage'a kaydetmek
i18n.on("languageChanged", saveLanguage);

export default i18n;
