import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/sass/pages/_partner-invoice.scss";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
const PartnersInvoice = () => {
  // Language
  const { t, i18n } = useTranslation();

  //   Navigate
  const navigate = useNavigate();

  //   SearchParams
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planType = queryParams.get("plan-type");

  /* --------------------------- General Info Check --------------------------- */
  const profileSchema = object({
    name: string().required().trim(),
    email: string().required().trim().email(),
    company: string().required().trim(),
    phone: string().required().trim(),
    description: string().required().trim(),
  });

  // Recat Hook Form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileSchema),
  });

  const invoice = async (data) => {
    const body = {
      name: data.name,
      email: data.email,
      company: data.company,
      phone: data.phone,
      description: ` ${planType} / ${data.description}`,
    };
    await axios
      .post(
        `https://api.uimconsulting.com/${i18n.language}/v3/partners/new`,
        body
      )
      .then((res) => {
        console.log(res);
        if (res.data.success === "success") {
          notify(t("partner_invoice.alert_succes"));
          //   setProfilData(body);
        } else {
          notify(t("partner_invoice.alert_succes"));
        }
        // else if (res.data.message === "Token expired!") {
        //   setLoading(false);
        //   navigate("/error");
        // } else {
        //   notify(t("persona_information.failed_process"));
        // }
      })
      .catch((err) => {
        if (err) {
          navigate("/error");
        }
      });
  };

  return (
    <section className="partners-invoice">
      <div className="container">
        <form action="" className="invoice" onSubmit={handleSubmit(invoice)}>
          <h2 className="caption">{t("partner_invoice.caption")}</h2>
          <div className="inp-area">
            {/*  */}
            <fieldset className="form-group">
              <legend
                className={
                  watch("name") === undefined || watch("name") === ""
                    ? "label"
                    : "label animate"
                }
              >
                {t("partner_invoice.fullname")}:
              </legend>
              <input type="text" name="name" {...register("name")} />
              {errors.name && (
                <div className="error-message">{errors.name.message}</div>
              )}
            </fieldset>
            {/* Email */}
            <fieldset className="form-group">
              <legend
                className={
                  watch("email") === undefined || watch("email") === ""
                    ? "label"
                    : "label animate"
                }
              >
                {t("partner_invoice.email")}:
              </legend>
              <input type="text" name="email" {...register("email")} />
              {errors.email && (
                <div className="error-message">{errors.email.message}</div>
              )}
            </fieldset>
            {/* Phone */}
            <fieldset className="form-group">
              <legend
                className={
                  watch("phone") === undefined || watch("phone") === ""
                    ? "label"
                    : "label animate"
                }
              >
                {t("partner_invoice.phone")}:
              </legend>
              <input
                type="tel"
                name="phone"
                {...register("phone")}
                onBeforeInput={(e) => {
                  if (!/[+0-9]/.test(e.data)) {
                    e.preventDefault();
                  }
                }}
              />
              {errors.phone && (
                <div className="error-message">{errors.phone.message}</div>
              )}
            </fieldset>
            {/* Company name */}
            <fieldset className="form-group">
              <legend
                className={
                  watch("company") === undefined || watch("company") === ""
                    ? "label"
                    : "label animate"
                }
              >
                {/* {t("persona_information.seria_code")}: */}
                {t("partner_invoice.company")}:
              </legend>
              <input type="text" name="company" {...register("company")} />
              {errors.company && (
                <div className="error-message">{errors.company.message}</div>
              )}
            </fieldset>
            <fieldset className="form-group">
              <legend
                className={
                  watch("description") === undefined ||
                  watch("description") === ""
                    ? "label"
                    : "label animate"
                }
              >
                {t("partner_invoice.organination_activity")}:
              </legend>
              <input
                type="text"
                name="description"
                {...register("description")}
              />
              {errors.description && (
                <div className="error-message">
                  {errors.description.message}
                </div>
              )}
            </fieldset>
            <div className="btn-area">
              <button className="btn"> {t("partner_invoice.btn")}</button>
            </div>
            <h3 className="sub-caption">
              {t("partner_invoice.subcaption")}:)
            </h3>
          </div>
        </form>
      </div>
    </section>
  );
};

export default PartnersInvoice;
