import { Link } from "react-router-dom";
import logo from "../assets/img/Kurs evi logo.svg";
import { useContext, useState } from "react";
import { MainContext } from "../utils/MainContext";
import { useTranslation } from "react-i18next";

const Footer = () => {
  // Global State
  const { contactData } = useContext(MainContext);

  const [langOpen, setLangOpen] = useState(false);
  // overlay yaz dil deyismede

  // Languge
  const { t, i18n } = useTranslation();

  // Change Language
  const selectLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="left-side">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            {contactData && "phone" in contactData ? (
              <ul className="contact-list">
                <li className="contact-item">
                  <span>{t("footer.phone")}:</span>
                  <Link
                    to={`https://wa.me/${contactData.whatsapp}`}
                    target="_blank"
                  >
                    {contactData.whatsapp}
                  </Link>
                </li>
                <li className="contact-item">
                  {t("footer.mail")}:
                  <Link to={`mailto:${contactData.email}`}>
                    {contactData.email}
                  </Link>
                </li>
              </ul>
            ) : (
              ""
            )}
          </div>
          <div className="right-side">
            <div className="subcribe-partner-info-area">
              <p className="subtext">
              {t("general.subuscribe_title")}
              </p>
              <Link className="btn" to="/subscribe">{t("general.subuscribe")}</Link>
            </div>
            <ul className="menu-list">
              <li className="menu-item">
                <Link to="/conference">{t("footer.conference")}</Link>
              </li>
              <li className="menu-item">
                <Link to="/courses">{t("footer.course")}</Link>
              </li>
              <li className="menu-item">
                <Link to="/contact">{t("footer.contact")}</Link>
              </li>
              {/* <li className="menu-item">
              <Link to="/">Sponsor</Link>
            </li> */}
              <li className="menu-item">
                <Link to="/privacy">{t("footer.privacy")}</Link>
              </li>
              <li className="menu-item">
                <Link to="/terms-of-use">{t("footer.terms-of-use")}</Link>
              </li>
              <li className="menu-item">
                <Link to="/agreement">{t("footer.agreement")}</Link>
              </li>
              {/* <li className="menu-item">
              <Link to="/faq">Faq</Link>
            </li> */}
            </ul>
          </div>
        </div>
        <div className="last">
          <div className="row">
            <div className="last-text">
              <p>{t("footer.policy")}</p>
            </div>
            <div className="language" onClick={(e) => setLangOpen(!langOpen)}>
              <div className="lang-icon icon">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.9961 17.2803C13.1382 17.2803 16.4961 13.9224 16.4961 9.78028C16.4961 5.63814 13.1382 2.28027 8.9961 2.28027C4.85396 2.28027 1.49609 5.63814 1.49609 9.78028C1.49609 13.9224 4.85396 17.2803 8.9961 17.2803Z"
                    stroke="#001D48"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.99922 3.03027H6.74922C5.28672 7.41028 5.28672 12.1503 6.74922 16.5303H5.99922"
                    stroke="#001D48"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.25 3.03027C12.7125 7.41028 12.7125 12.1503 11.25 16.5303"
                    stroke="#001D48"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.24609 12.7803V12.0303C6.6261 13.4928 11.3661 13.4928 15.7461 12.0303V12.7803"
                    stroke="#001D48"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.24609 7.53047C6.6261 6.06797 11.3661 6.06797 15.7461 7.53047"
                    stroke="#001D48"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span className="curent-lang">
                {i18n.language === "az"
                  ? "Azərbaycan"
                  : i18n.language === "en"
                  ? "English"
                  : "Русский"}
                <ul className={langOpen ? "lang-list active" : "lang-list "}>
                  <li
                    className="lang-item"
                    onClick={() => {
                      setLangOpen(false);
                      selectLanguage("az");
                    }}
                  >
                    Azərbaycan
                  </li>
                  <li
                    className="lang-item "
                    onClick={() => {
                      setLangOpen(false);
                      selectLanguage("en");
                    }}
                  >
                    English
                  </li>
                  {/* <li className="lang-item " onClick={() =>{ setLangOpen(false)
                    selectLanguage("ru")
                  }}>
                    Русский
                  </li> */}
                </ul>
              </span>
              <div
                className={
                  langOpen ? "drop-icon icon active-icon" : "drop-icon icon"
                }
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.9984 5.37939L8.04692 9.78884C7.58026 10.3096 6.81662 10.3096 6.34995 9.78884L2.39844 5.37939"
                    stroke="#001D48"
                    strokeWidth="1.2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
