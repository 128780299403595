import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
export const Auth = createContext();

export const FormSchema = ({ children }) => {
  /* ---------------------------------- State --------------------------------- */
  const [form, setForm] = useState("sing in");
  const [confirmRegisterMail, setConfirmRegisterMail] = useState("");
  const [forgetPassowrd, setForgetPassowrd] = useState({});
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  const [profilData, setProfilData] = useState(null);

  // Form Visible
  const [formArea, setFormArea] = useState(false);

  // PersonalINformation in Profile Page
  const [changePassVisible, setChangePassVisible] = useState(false);
  const [selectVisible, setSelectVisible] = useState("");
  // React Router Dom
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location.pathname.split("/")[1]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // console.log();

  // /calendar
  // /
  // /contact
  // /innovatons
  // innovaton-detail
  // partners
  // partner-detail
  // privacy
  // terms-of-use
  // agreement

  // LocalStorage Get Data
  useEffect(() => {
    const getToken = () => {
      const saveData = JSON.parse(localStorage.getItem("token"));
      const pages = [
        "",
        "calendar",
        "contact",
        "innovatons",
        "innovaton-detail",
        "partners",
        "partner-detail",
        "privacy",
        "terms-of-use",
        "agreement",
        "spiker-detail",
      ];
      if (saveData !== null) {
        axios
          .get(
            `https://api.uimconsulting.com/en/v3/auths/checktoken?token=${saveData}&type=4`,
            {
              crossdomain: true,
            }
          )
          .then((res) => {
            if (
              res.data.status === false ||
              res.data.message === "Token expired!"
            ) {
              setToken("");
              // profil seyfeden asilli ele
              if (!pages.includes(location.pathname.split("/")[1])) {
                navigate("/");
              }
            } else if (res.data.status === "success") {
              setToken(saveData);
            }
          })
          .catch((err) => console.log(err));
      }
    };
    getToken();
  }, [setToken]);

  // Request Profil
  useEffect(() => {
    if (token) {
      axios
        .get(
          `https://api.uimconsulting.com/en/v3/users/profile?token=${token}`,
          {
            crossdomain: true,
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            setProfilData(res.data.payload);
          }
        })
        .catch((err) => {
          if (err) {
            setToken("");
          }
        });
    }
  }, [token]);

  const logOut = () => {
    localStorage.setItem("token", JSON.stringify(false));
    setToken("");
    setProfilData(null);
    navigate("/");
  };

  const globalStates = {
    // State
    form,
    setForm,
    forgetPassowrd,
    setForgetPassowrd,
    confirmRegisterMail,
    setConfirmRegisterMail,
    otp,
    setOtp,
    token,
    setToken,
    formArea,
    setFormArea,

    // Profile
    changePassVisible,
    setChangePassVisible,
    selectVisible,
    setSelectVisible,
    profilData,
    setProfilData,

    // Function
    logOut,
  };
  return <Auth.Provider value={globalStates}>{children}</Auth.Provider>;
};
