import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Link, useNavigate } from "react-router-dom";
import arrow from "../../assets/img/icon/arrow-right-link.svg";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";

const Innovation = () => {
  // Local State
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Navigate
  const navigate = useNavigate();
  const {t,i18n}=useTranslation()
  // Get Request Schema
  const getNewsListData = useCallback(
    async (page_number) => {
      setLoading(true);
      await axios
        .get(
          `https://api.uimconsulting.com/${i18n.language}/v3/newslists?page=${page_number}&per-page=10`,
          {
            crossdomain: true,
          }
        )
        .then((res) => {
          let copiedData = [];
          let data = res.data;
          if (data.length < 4) {
            let times = 1;
            if (data.length === 4) times = 2;
            if (data.length === 3) times = 3;
            if (data.length === 2) times = 5;
            if (data.length === 1) times = 9;

            // for (let i = 0; i < times; i++) {
            //   copiedData = copiedData.concat(data);
            // }

            for (let i = 0; i < times; i++) {
              copiedData = copiedData.concat(
                data.map((item, index) => ({
                  ...item,
                  additionId: `${index}-${i}`,
                }))
              );
            }

            setNewsData(copiedData);
          } else {
            copiedData = data;
          }

          setNewsData(copiedData);
          setLoading(false);
        })
        .catch((err) => {
          if (err) {
            navigate("/error");
            setLoading(false);
          }
        });
    },
    [i18n.language, navigate]
  );
  
  // Render Data
  useEffect(() => {
    getNewsListData(1);
  }, [getNewsListData]);
  return (
    <>
      {loading && <Loading />}
      {newsData.length !== 0 && (
        <section className="innovation">
          <div className="container">
            <Link to="/innovatons" className="innovation-title">
             {t("header.innovatons")}
              <div className="icon">
                <img src={arrow} alt="icon" />
              </div>
            </Link>
            <div className="innovation-slider">
              <Swiper
                slidesPerView={1}
                spaceBetween={16}
                navigation={true}
                centeredSlides={true}
                loop={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  300: {
                    slidesPerView: 1.4,
                    spaceBetween: 16,
                  },
                  350: {
                    slidesPerView: 1.9,
                    spaceBetween: 16,
                  },
                  485: {
                    slidesPerView: 2.2,
                    spaceBetween: 16,
                  },
                  575: {
                    slidesPerView: 2.7,
                    spaceBetween: 16,
                  },

                  650: {
                    slidesPerView: 3.2,
                    spaceBetween: 16,
                  },

                  850: {
                    slidesPerView: 3.5,
                    spaceBetween: 16,
                  },

                  1000: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
                modules={[Navigation, Autoplay]}
                className="mySwiper"
              >
                {newsData.map((data) => (
                  <SwiperSlide key={data.additionId}>
                    <Link
                      className="inovation-card"
                      to={`/innovaton-detail/${data.id}`}
                    >
                      <div className="top">
                        <img
                          src={`${process.env.REACT_APP_IMG}/uploads/news/${data.image}`}
                          alt={data.title}
                        />
                      </div>
                      <div className="bottom">
                        <span className="date">{data.date.split(" ")[0]}</span>
                        <h2 className="title">{data.title}</h2>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{ __html: data.text }}
                        />
                        <span className="info">
                          Daha ətraflı
                          <div className="icon">
                            <img src={arrow} alt="icon" />
                          </div>
                        </span>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Innovation;
