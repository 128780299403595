// React
import { useEffect, useState } from "react";

//  Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

// Navigate
import { useNavigate } from "react-router-dom";

const LandingPageSlider = ({ data }) => {
  // React Router Dom
  const navigate = useNavigate();
  // Local State
  const [loaded, setLoaded] = useState(false);

  // Load Rendering
  useEffect(() => {
    if (data) {
      setLoaded(false);
    }
  }, [data]);

  return (
    data && (
      <section className="landing-page">
        <Swiper
          slidesPerView={1}
          spaceBetween={-1}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {data.banners.map((banner) => (
            <SwiperSlide key={banner.id}>
              <div
                className="langing-area"
                onClick={() =>
                  navigate(
                    `/general-detail/${
                      banner.type === 1 ? "courses" : "conferences"
                    }/${banner.typeid}`
                  )
                }
              >
                <div className="bg-img">
                  {!loaded && <div className="placeholder"></div>}
                  <img
                    className="desktop-bg-img"
                    fetchpriority="high"
                    onLoad={() => setLoaded(true)}
                    style={{ display: loaded ? "block" : "none" }}
                    src={`${process.env.REACT_APP_IMG}/uploads/sliders/${banner.filename}`}
                    alt={banner.title}
                  />
                  <img
                    className="mobile-bg-img"
                    fetchpriority="high"
                    onLoad={() => setLoaded(true)}
                    style={{ display: loaded ? "block" : "none" }}
                    src={`${process.env.REACT_APP_IMG}/uploads/sliders/${banner.filenamemob}`}
                    alt={banner.title}
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    )
  );
};

export default LandingPageSlider;
