import { useCallback, useContext, useEffect } from "react";
import calendar from "../assets/img/icon/calendar.svg";
import CalendarModal from "./MiniCalendar";
import { MainContext } from "../utils/MainContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CalendarEventList = ({ course, conference, filterType }) => {
  const {
    calendarVisible,
    setCalendarVisible,
    selectRangeDate,
    setHandleEdit,
    startDate,
    endDate,
    listData,
    setListData,
    setSelectRangeDate,
    setStartDate,
    setEndDate,
  } = useContext(MainContext);
  // window.scrollY > 150

  const { t } = useTranslation();

  // Controller Data
  const filteredData = useCallback(() => {
    //
    //
    //
    // Course
    //
    //
    //
    if (filterType === "course") {
      //
      // Both
      //
      if (selectRangeDate.start && selectRangeDate.end) {
        const filteredEvents = course.filter(
          (event) =>
            new Date(event.date) >= new Date(selectRangeDate.start) &&
            new Date(event.date) <= new Date(selectRangeDate.end)
        );

        setListData(filteredEvents);
      }
      //
      // Min
      //
      else if (selectRangeDate.start && selectRangeDate.end === "") {
        const filteredEvents = course.filter(
          (event) => new Date(event.date) > new Date(selectRangeDate.start)
        );
        setListData(filteredEvents);
      }
      //
      // Max
      //
      else if (selectRangeDate.start === "" && selectRangeDate.end) {
        const filteredEvents = course.filter(
          (event) => new Date(event.date) < new Date(selectRangeDate.end)
        );
        setListData(filteredEvents);
      }
    }
    //
    //
    //
    // Conference
    //
    //
    //
    else {
      //
      // Both
      //
      if (selectRangeDate.start && selectRangeDate.end) {
        const filteredEvents = conference.filter(
          (event) =>
            new Date(event.date) >= new Date(selectRangeDate.start) &&
            new Date(event.date) <= new Date(selectRangeDate.end)
        );

        setListData(filteredEvents);
      }
      //
      // Min
      //
      else if (selectRangeDate.start && selectRangeDate.end === "") {
        const filteredEvents = conference.filter(
          (event) => new Date(event.date) > new Date(selectRangeDate.start)
        );
        setListData(filteredEvents);
      }
      //
      // Max
      //
      else if (selectRangeDate.start === "" && selectRangeDate.end) {
        const filteredEvents = conference.filter(
          (event) => new Date(event.date) < new Date(selectRangeDate.end)
        );
        setListData(filteredEvents);
      }
    }
  }, [
    conference,
    course,
    filterType,
    selectRangeDate.end,
    selectRangeDate.start,
    setListData,
  ]);

  // Render Data While Mobile Size
  useEffect(() => {
    if (window.scrollY < 769) {
      filteredData();
    }
  }, [filteredData, selectRangeDate]);

  const formatDateString = (dateString) => {
    const date = new Date(dateString);

    // Gün, ay ve yılı almak
    const day = String(date.getDate()).padStart(2, "0"); // Gün
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ay (0 bazlı olduğu için +1)
    const year = date.getFullYear(); // Yıl

    // Formatlama
    return `${day}/${month}/${year}`;
  };
  return (
    <>
      <div className="calendar-range">
        <div
          className="calendar-group"
          onClick={() => {
            setCalendarVisible(true);
            setHandleEdit("start");
          }}
        >
          <div className="icon">
            <img src={calendar} alt="calendar" />
          </div>
          <div className="info-area">
            <h5 className="caption">{t("calendar.start_date")}</h5>
            <p className="date">
              {startDate ? startDate : t("calendar.choose_date")}
            </p>
          </div>
        </div>
        <div
          className="calendar-group"
          onClick={() => {
            setCalendarVisible(true);
            setHandleEdit("end");
          }}
        >
          <div className="icon">
            <img src={calendar} alt="calendar" />
          </div>
          <div className="info-area">
            <h5 className="caption">{t("calendar.end_date")}</h5>
            <p className="date">
              {endDate ? endDate : t("calendar.choose_date")}
            </p>
          </div>
        </div>
      </div>
      {window.scrollY < 769 &&
        (selectRangeDate.start || selectRangeDate.end) && (
          <div className="reset-date">
            <button
              className="reset"
              onClick={() => {
                setSelectRangeDate({
                  start: "",
                  end: "",
                });

                setStartDate("");
                setEndDate("");
                setHandleEdit("");
              }}
            >
              {t("calendar.reset_date")}
            </button>
          </div>
        )}

      <div className="event-info scroll">
        <div className="head">
          <h2 className="caption">{t("calendar.list")}</h2>
          {/* <div className="download-pdf">PDF yüklə</div> */}
        </div>
        <ul className="list">
          {listData.length === 0 ? (
            <li className="list-item">
              <p className="alert">
                {filterType === "course"
                  ? t("calendar.course-list-info")
                  : t("calendar.conference-list-info")}
              </p>
            </li>
          ) : (
            listData.map((data) => (
              <li className="list-item" key={data.id}>
                <p className="date">{formatDateString(data.date)} {formatDateString(data.date) !==formatDateString(data.eventEndDate) ?  `- ${formatDateString(data.eventEndDate)}`: ""}</p>
                <p className="hour">
                  {t("banner_detail.hours")} {data.time}
                </p>
                <p
                  className="event-name"
                  style={{ borderLeftColor: data.color }}
                >
                  {data.title}{" "}
                  <Link
                    to={`/general-detail/${
                      data.type === 1 ? "courses" : "conferences"
                    }/${data.id}`}
                  >
                    {t("general.read_more")}
                  </Link>
                </p>
              </li>
            ))
          )}
        </ul>
      </div>
      {calendarVisible && <CalendarModal />}
    </>
  );
};

export default CalendarEventList;
