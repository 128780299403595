import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const LastAddSlider = ({ data }) => {
  const { t } = useTranslation();
  const [conferenceData, setConferenceData] = useState([]);
  const [courseData, setCourseData] = useState([]);

  useEffect(() => {
    if (data) {
      setConferenceData(data[0].conferences);
      setCourseData(data[0].courses);
    }
  }, [data]);

  return (
    (conferenceData.length !== 0 || courseData.length !== 0) && (
      <section className="last-add">
        <div className="container">
          <Link className="last-add-title">
            {t("home.last_add")}
            <svg
              width="52"
              height="26"
              viewBox="0 0 52 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.2656 6.42407L44.4173 12.9999L31.2656 19.5757"
                stroke="#0046AC"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.58203 13H44.047"
                stroke="#0046AC"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
          <div className="last-add-slider">
            <Swiper
              slidesPerView={1.3}
              spaceBetween={16}
              navigation={true}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                // 400: {
                //   slidesPerView: 1.9,
                //   spaceBetween: 16,
                //   centeredSlides: true,
                // },
                // 575: {
                //   slidesPerView: 2.4,
                //   spaceBetween: 16,
                //   centeredSlides: true,
                // },
                // 700: {
                //   slidesPerView: 3.3,
                //   spaceBetween: 16,
                //   centeredSlides: true,
                // },

                // //  600: {
                // //     slidesPerView: 4,
                // //     spaceBetween: 20,
                // //     centeredSlides: true,
                // //   },
                // 1024: {
                //   slidesPerView: 4,
                //   spaceBetween: 20,
                //   centeredSlides: true,
                // },
                300: {
                  slidesPerView: 1.4,
                  spaceBetween: 16,
                },
                350: {
                  slidesPerView: 1.9,
                  spaceBetween: 16,
                },
                485: {
                  slidesPerView: 2.2,
                  spaceBetween: 16,
                },
                575: {
                  slidesPerView: 2.7,
                  spaceBetween: 16,
                },

                650: {
                  slidesPerView: 3.2,
                  spaceBetween: 16,
                },

                850: {
                  slidesPerView: 3.5,
                  spaceBetween: 16,
                },

                1000: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
              modules={[Navigation, Autoplay]}
              className="mySwiper"
            >
              {conferenceData.map((item) => (
                <SwiperSlide key={item.id}>
                  <Card data={item} key={item.id} type={"conferences"} />
                </SwiperSlide>
              ))}

              {/* {data &&
              data.courses.map((item) => (
                <SwiperSlide key={item.id}>
                  <Card data={item} key={item.id} type={"cource"} />
                </SwiperSlide>
              ))} */}
              {courseData.map((item) => (
                <SwiperSlide key={item.id}>
                  <Card data={item} key={item.id} type={"cource"} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    )
  );
};

export default LastAddSlider;
