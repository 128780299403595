import { useCallback, useContext, useEffect, useState } from "react";
import letter from "../assets/img/icon/sms-tracking (1).svg";
import { Auth } from "../utils/Auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { infoAlert } from "../utils/SweetAlert";
import { notify } from "../utils/ToastIfyNonfoction";

const RegisterConfirm = () => {
  /* ------------------------------ Gloabal State ----------------------------- */
  const { form, setForm, confirmRegisterMail } = useContext(Auth);

  /* ------------------------------- Local State ------------------------------ */
  const [minute, setMinute] = useState(4);
  const [second, setSecond] = useState(59);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorText, setErrorText] = useState("");

  const { i18n, t } = useTranslation();

  /* ---------------------------------- Timer --------------------------------- */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (minute === 0 && second === 0) {
        setMinute(0);
        setSecond(0);
      } else {
        if (second > 0) {
          setSecond(second - 1);
        } else {
          setMinute(minute - 1);
          setSecond(59);
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [minute, second]);

  // REset State
  useEffect(() => {
    if (form) {
      setIsSubmitting(false);
    }
  }, [form]);

  // Yup Schema
  const otpSchema = object({
    otp: string().required().trim(),
  });

  // React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(otpSchema),
  });

  // Get Request For NewRegToken
  const newOtpResend = async () => {
    await axios
      .get(
        `https://api.uimconsulting.com/${i18n.language}/v3/users/newregtoken?email=${confirmRegisterMail}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        if (res.data.success === "success") {
          setMinute(4);
          setSecond(59);
        } else {
          infoAlert(t("general.failed_caption"), t("general.failed_detail"));
        }
      })
      .catch((err) => console.log(err));
  };

  // Get Request For Confirm Registration
  const confirmRegistration = useCallback(
    async (otp) => {
      await axios
        .get(
          `https://api.uimconsulting.com/${i18n.language}/v3/users/confirmuser?token=${otp}`,
          {
            crossdomain: true,
          }
        )
        .then((res) => {
          setIsSubmitting(false);
          if (res.data.status === "success") {
            setForm("sing in");
            notify(t("register.reg_success"));
          } else {
            infoAlert(t("general.failed_caption"), t("general.failed_detail"));
          }
        })
        .catch((err) => err && setIsSubmitting(false));
    },
    [i18n.language, setForm, t]
  );

  // Submit Button
  const onSubmit = async (data) => {
    if (!isSubmitting) {
      setIsSubmitting(true);

      // Check Token
      await axios
        .get(
          `https://api.uimconsulting.com/en/v3/auths/checktoken?token=${data.otp}&type=0`,
          {
            crossdomain: true,
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            confirmRegistration(data.otp);
          } else {
            setIsSubmitting(false);
            setErrorText(t("otp.error"));
          }
        })
        .catch((err) => err && setIsSubmitting(false));
    }
  };

  return (
    <div className="fotgot-otp-container">
      <div className="head-icon">
        <img src={letter} alt="letter" />
      </div>
      <div className="otp-body">
        <h5 className="otp-title"> {t("otp.caption")} </h5>
        <form
          className="otp-form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="input-area">
            <fieldset className={errors.otp ? "inp error" : "inp"}>
              <legend>OTP</legend>
              <input
                type="text"
                name="otp"
                {...register("otp", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </fieldset>
            {errors.otp && (
              <div className="error-message">{errors.otp.message}</div>
            )}
            {errorText && <div className="error-message">{errorText}</div>}
          </div>
          <span className="time">
            0{minute}: {second < 10 ? `0${second}` : second}
          </span>
          <p className="text">{t("otp.detail")}</p>
          <span className="otp-forget-link" onClick={() => newOtpResend()}>
            {t("otp.resend_otp")}
          </span>{" "}
          <button className="btn">{t("otp.submit")}</button>
        </form>
      </div>
    </div>
  );
};

export default RegisterConfirm;
