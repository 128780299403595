import React, { useEffect, useState } from "react";
import PersonaolCard from "../../components/PersonaolCard";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const ConfranceCourseSpikers = ({ data }) => {
  // Local State
  const [handleCountDatas, setHandleCountDatas] = useState(6);
  const { t } = useTranslation();

  // Location
  const location = useLocation();

  useEffect(() => {
    if (location) {
      setHandleCountDatas(6);
    }
  }, [location]);
  return (
    <section className="course-conferance-spiker">
      <div className="container">
        <div className="bg-text">
          <span className="text">{t("speaker.speaker_bg_text")}</span>
          <h6 className="title">
            {t("speaker.speaker_before_text")}{" "}
            <span>{t("speaker.speaker_after_text")}</span>
          </h6>
        </div>
        <div className="spikers">
          {data.slice(0, handleCountDatas).map((speaker) => (
            <PersonaolCard key={speaker.id} speaker={speaker} />
          ))}
        </div>
        {Number(data.length) > 6 && (
          <div
            className="btn-area"
            onClick={() => {
              if (handleCountDatas === 6) {
                setHandleCountDatas(Number(data.length));
              } else {
                setHandleCountDatas(6);
              }
            }}
          >
            <button className="more">
              {handleCountDatas === 6
                ? t("general.read_more")
                : t("general.hide")}{" "}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default ConfranceCourseSpikers;
