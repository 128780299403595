import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
import axios from "axios";
import DetailCardArea from "../components/DetailCardArea";

// Icon
import notFoundImg from "../assets/img/IMG-not-found.svg";
// import degreeIcon from "../assets/img/icon/speaker-detail/degree.svg";
import location from "../assets/img/icon/speaker-detail/location.svg";
import emailIcon from "../assets/img/icon/speaker-detail/email.svg";
import phoneIcon from "../assets/img/icon/speaker-detail//phone.svg";
import workplaceIcon from "../assets/img/icon/speaker-detail/workplace.svg";

const SpikerDetail = () => {
  // LocalState
  const [speakerData, setSpeakerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  // Navigate
  const navigate = useNavigate();

  //
  const { i18n } = useTranslation();

  const { speakerId } = useParams();

  const getSpeakerData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(
        `https://api.uimconsulting.com/${i18n.language}/v3/speakers/${speakerId}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        setSpeakerData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err) {
          navigate("/error");
          setLoading(false);
        }
      });
  }, [i18n.language, navigate, speakerId]);

  useEffect(() => {
    if (speakerId && i18n.language) getSpeakerData();
  }, [getSpeakerData, i18n.language, speakerId]);

  return (
    <>
      <section className="spiker-general-info">
        {loading && <Loading />}
        {speakerData && (
          <div className="container">
            <div className="row">
              <div className="person-img">
              {!loaded && speakerData.photo && <div className="placeholder"></div>}
                {speakerData.photo ? (
                  <img
                    src={`${process.env.REACT_APP_IMG}/uploads/speakers/${speakerData.photo}`}
                    alt={`${speakerData.firstname} ${speakerData.lastname}`}
                    fetchpriority="high"
                    onLoad={() => setLoaded(true)}
                    style={{ display: loaded ? "block" : "none" }}
                  />
                ) : (
                  <img
                    src={notFoundImg}
                    alt={`${speakerData?.firstname} ${speakerData?.lastname}`}
                  />
                )}
              </div>
              <div className=" persnoality-general-info ">
                <h3 className="title">
                  {speakerData?.salutation} {speakerData?.firstname}{" "}
                  {speakerData?.lastname} {speakerData?.middlename}
                </h3>
                {/* {speakerData.degree && (
                  <p className="personal-info">
                    <div className="icon">
                      <img src={degreeIcon} alt="degree" />
                    </div>
                    {speakerData.degree}
                  </p>
                )} */}
                {(speakerData.country || speakerData.city) && (
                  <p className="personal-info">
                    <div className="icon">
                      <img src={location} alt="location" />
                    </div>
                    {speakerData.country}
                    {speakerData.country && speakerData.city ? " / " : ""}
                    {speakerData.city}
                  </p>
                )}
                {speakerData.workplace && (
                  <p className="personal-info">
                    <div className="icon">
                      <img src={workplaceIcon} alt="workplace" />
                    </div>
                    {speakerData.workplace}
                  </p>
                )}
                {speakerData.email && (
                  <p className="personal-info specific">
                    <div className="icon">
                      <img src={emailIcon} alt="email" />
                    </div>
                    {speakerData.email}
                  </p>
                )}
                {speakerData.phone && (
                  <p className="personal-info specific">
                    <div className="icon">
                      <img src={phoneIcon} alt="phone" />
                    </div>
                    {speakerData.phone}
                  </p>
                )}
              </div>
            </div>
            {speakerData.biography && (
              <div className="innovation-biography ">
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: speakerData.biography }}
                />
              </div>
            )}
          </div>
        )}
      </section>
      {speakerData && (
        <>
          {typeof speakerData.conferences !== "string" && (
            <DetailCardArea
              datas={speakerData.conferences}
              actionType={"conferneces"}
            />
          )}
          {typeof speakerData.courses !== "string" && (
            <DetailCardArea
              datas={speakerData.courses}
              actionType={"courses"}
            />
          )}
        </>
      )}
    </>
  );
};
export default SpikerDetail;
