const TermsOfUse = () => {
  return (
    <section className="additional-page">
      <div className="container">
        <div className="content">
          <h4>KursEvi </h4>
          <h4>İstifadə qaydaları</h4>
          <p>
            <em>Son versiya: 10 May 2020 –ci il</em>
          </p>
          <p>
            <strong>
              <u>Mobil tətbiqi və portaldan istifadə</u>
            </strong>
          </p>
          <p>
            <strong>“KursEvi”</strong> mobil tətbiqi <strong>www.</strong>
            <strong>destek@ezgil.az</strong> ünvanında və hazırki İstifadə
            Qaydalarına keçidləri olan digər əlaqədar vebsaytlarda (o cümlədən,
            w
            <strong>
              <u>ww.topdoktor.az</u>
            </strong>{" "}
            ünvanında yerləşən vebsaytda) və mobil tətbiqlərdə (birlikdə “
            <strong>Mobil Tətbiq və Sayt</strong>”) fəaliyyət göstərir. Biz, bu
            tətbiq və saytdan istifadə edən üzvlərimizə (“
            <strong>Üzvlər</strong>”) öz sağlamlıq tarixçələri barədə məlumat
            almaq və sağlamlıqla bağlı məsləhətləşmələr almaq üçün Tibb
            Mütəxəssislərini cəlb etmək (“Tibb Mütəxəssisləri”) imkanı verən
            online tele–səhiyyə xidmətləri təklif edirik. Mobil tətbiq və sayta
            daxil olaraq və onlardan istifadə edərək,&nbsp; siz, hazırki
            İstifadə Qaydalarına və Mobil tətbiq və saytda olan bütün digər
            şərtlərə və qaydalara əməl etməyə razılaşırsınız. Bu İstifadə
            Qaydalarından hər hansı birinə əməl etmək istəmədiyiniz halda, siz,
            &nbsp;saytdan və ya xidmətlərdən istifadə edə bilməzsiniz.
          </p>
          <p>
            <strong>
              <u>Səhiyyə xidmətləri </u>
            </strong>
          </p>
          <p>
            <strong>“KursEvi”</strong> vasitəsilə xidmətlər göstərən Tibb
            Mütəxəssisləri ümumilikdə “
            <strong>KursEvi-in mütəxəssisləri</strong>” adlandırılan müstəqil
            mütəxəssislərdir. “<strong>KursEvi” </strong>tibb və ya hər hansı
            digər lisenziyalaşdırılmış peşə fəaliyyəti ilə məşğul deyil, müalicə
            və hər biri öz xidmətləri və onların xidmət və lisenziyasına şamil
            olunan tələblərə uyğunluq üçün cavabdehlik daşıyan, Tibb
            Mütəxəssisləri tərəfindən hər hansı digər lisenziyalaşdırılmış
            fəaliyyətə müdaxilə etmir. <strong>KursEvi</strong>&nbsp;, nə də
            Xidmətləri təşviq edən və ya sizi Xidmətlərə keçid ilə təmin edən
            hər hansı üçüncü tərəf Xidmətlər vasitəsilə Tibb Mütəxəssislərindən
            əldə etdiyiniz hər hansı peşəkar məsləhət və ya tövsiyyə üçün
            cavabdehlik daşımır.
          </p>
          <p>
            <strong>
              Mobil Tətbiq və Saytın<u> məzmunu</u>
            </strong>
          </p>
          <p>
            Mobil Tətbiq və Saytın məzmunu (Tibb Mütəxəssislərindən aldığınız
            məlumatdan başqa) hər hansı xüsusi dərman vasitəsi və ya müalicənin
            sizin üçün təhlükəsiz, uyğun və ya effektiv olduğuna dair tibbi
            məsləhət və ya təsdiq, təqdimat &nbsp;və ya zəmanət hesab
            olunmamalıdır.
          </p>
          <p>
            <strong>
              <u>İstifadəçi hesabları</u>
            </strong>
          </p>
          <p>
            Saytda qeydiyyatdan keçən zaman sizdən tam adınızı, elektron poçt
            (email) ünvanınızı, parol və <strong>KursEvi</strong>&nbsp;
            tərəfindən toplanan digər müəyyən məlumatları (ümumilikdə “
            <strong>Hesab Məlumatları</strong>”) daxil edərək hesab (“
            <strong>Hesab</strong>”) yaratmaq tələb olunur. Hesab yaratmaq üçün
            siz məcburi müqaviləni bağlamaq üçün ən azı 18 yaşdan yuxarı
            olmalısınız. Əgər məcburi müqaviləni bağlamaq üçün tələb olunan
            hüquqi yaşda olmasanız, siz Xidmətlərimizdən istifadə üçün
            qeydiyyatdan keçə bilməzsiniz. Hər zaman, o cümlədən qeydiyyat
            zamanı bizə təqdim etdiyiniz hesab məlumatlarınızın və{" "}
            <strong>Mobil Tətbiq və Sayt</strong>a yüklədiyiniz istənilən
            məlumatın doğru, dəqiq, cari və tam olduğu ilə razılaşırsınız. Hesab
            parolunuzu başqasına ötürə və ya onun ilə paylaşa və ya birdən çox
            Hesab yarada bilməzsiniz (valideyni və ya hüquqi qəyyumu olduğunuz
            uşaqlar üçün yaradılmış alt hesablar istisna olmaqla). Siz Hesab
            parolunuzun məxfiliyinin qorunub saxlanılması və hesabınızda baş
            verən bütün fəaliyyətlər üçün cavabdehlik daşıyırsınız.{" "}
            <strong>KursEvi</strong>, <strong>Mobil Tətbiq və Sayt</strong> və
            Hesab Məlumatlarınızın təhlükəsizliyi ilə bağlı zəruri və ya əsaslı
            hesab etdiyiniz istənilən və bütün əməliyyatları &nbsp;yerinə
            yetirmək hüququna malikdir. Heç bir halda <strong>KursEvi, </strong>
            bu <strong>Mobil Tətbiq və Saytda</strong> Hesab məlumatlarından
            istifadə etməyiniz və ya hesabınızda olan məlumatların kimsə
            tərəfindən üçüncü tərəfə ötürülməsi ilə bağlı və ya bunun
            nəticəsində baş verən hər hansı zərər və ya öhdəlik üçün sizin
            qarşınızda cavabdehlik daşımayacaqdır. Siz heç vaxt başqasının
            hesabından istifadə edə bilməzsiniz.
          </p>
          <p>
            <strong>
              <u>Uşaqların Xidmətlərdən istifadə etməsi</u>
            </strong>
          </p>
          <p>
            Xidmətlərin &nbsp;uşaqlar tərəfindən istifadəsi mümkündür, lakin
            uşaqlar Üzv olmaq səlahiyyətinə malik deyil və onlar Xidmətlərdən
            valideyn və ya hüquqi qəyyumlarının nəzarəti olmadan istifadə edə
            bilməzlər. Əgər siz azyaşlı uşağın adından valideyn və ya hüquqi
            qəyyum kimi qeydiyyatdan keçsəniz, bu zaman siz hazırki İstifadə
            Qaydalarına əməl edilməsi üçün tam cavabdehlik daşıyacaqsınız.
          </p>
          <p>
            <strong>
              <u>Giriş hüquqları</u>
            </strong>
          </p>
          <p>
            Biz bununla sizə <strong>Mobil Tətbiq və Sayta</strong> giriş və
            Xidmətlərdən yalnız şəxsi, qeyri–kommersiya məqsədilə və yalnız bu
            İstifadə Qaydalarına və bizim ilə bağlayacağınız hər hansı ayrıca
            müqavilələrə əsasən icazə verilən qaydada istifadə etmək üçün
            məhdud, qeyri–müstəsna, başqalarına ötürülə bilməyən hüquq veririk
            (“Giriş hüquqları”). Biz öz ixtiyarımızla istənilən səbəbdən hər kəs
            üçün Saytdan və ya Xidmətlərdən istifadəni inkar etmək və ya
            dayandırmaq hüququna sahibik. Siz aşağıdakıları etməyəcəyiniz və
            onları etməyə cəhd göstərməyəcəyiniz barədə razılaşırsınız (a) hər
            hansı fiziki və ya hüquqi şəxsi təqlid etmək və ya digər şəkildə
            fiziki və ya hüquqi şəxs ilə əlaqənizi təhrif etmək (b) Sayt və ya
            Xidmətlərdən hər hansı yerli, dövlət, milli və ya beynəlxalq qanunu
            pozmaq üçün istifadə etmək (c) Mobil Tətbiq və Sayt və ya
            Xidmətlərin hər hansı proqram təminatı və ya digər komponentlərinin
            dizaynını dəyişmək, sökmək, hissələrə ayırmaq və ya tərcümə etmək;
            (d) Sayt vasitəsilə virus və ya digər zərərli kompüter kodlarını
            yaymaq &nbsp;və ya (e) digər şəkildə yuxarıda qeyd olunan
            istifadənin əhatə dairəsini aşacaq şəkildə Xidmətlərdən və ya Mobil
            Tətbiq və Saytdan istifadə etmək. Bundan əlavə, siz, Sayt vasitəsilə
            Tibb Mütəxəssisləri ilə əlaqə saxlayan zaman qeyri–müvafiq hesab
            olunacaq təhqiramiz danışıq və davranışdan yaxud qanunsuz və ya
            qeyri–qanuni davranışdan çəkinməyə və{" "}
            <strong>Mobil Tətbiq və Saytdan</strong> kənar tele–səhiyyə
            xidmətləri üçün Tibb Mütəxəssisləri ilə əlaqə saxlamamağa
            razılaşırsınız. “<strong>KursEvi”</strong>&nbsp;{" "}
            <strong>Mobil Tətbiq və Sayt</strong> vasitəsilə aparılan Tibb
            Mütəxəssisləri ilə olan hər hansı qarşılıqlı əlaqə üçün cavabdehlik
            daşımayacaqdır. Biz ciddi şəkildə ictimai kompüterlərdə Xidmətlərdən
            istifadə etməməyi tövsiyyə edirik. Biz, həmçinin veb brauzerin və ya
            digər proqram təminatında Hesab parolunuzu saxlamamağı da tövsiyyə
            edirik.
          </p>
          <p>
            <strong>
              <u>Haqqlar və satınalma şərtləri </u>
            </strong>
          </p>
          <p>
            Siz Hesabınız üçün bütün haqqları və ya ödənişləri haqq və ya
            ödənişin ödənilməli və ödəniləcək olduğu vaxtda qüvvədə olan
            haqqlara, ödənişlərə və fakturalandırma şərtlərinə əsasən ödəməyə
            razılaşırsınız. Kredit kartı nömrəsini, PayPal və ya &nbsp;digər
            hesabınızı və əlaqədar ödəniş məlumatlarını <strong>KursEvi</strong>
            -da qeyd etməklə, siz, <strong>KursEvi</strong>&nbsp;-a ödənilməli
            və ödəniləcək bütün haqlar və ödənişlər üçün{" "}
            <strong>KursEvir</strong>-un hesab &nbsp;fakturasını birbaşa olaraq
            hesabınıza göndərmək səlahiyyəti verməyə və heç bir əlavə bildiriş
            və ya razılığın tələb olunmadığı ilə &nbsp;razılaşırsınız.
          </p>
          <p>
            Sağlamlıq planınızı “KursEvi” müştərək şəkildə işəgötürən və yaxud
            agentlik ilə birlikdə xidmət haqqını və yaxud xidmət haqqının bir
            hissəsini ödəməyi razılaşdırmışdırsa və yaxud xidmət haqqı “KursEvi”
            ilə birlikdə təyin edilən bir neçə digər razılaşdırma planına
            uyğundursa, belə halda ödənişin tənzimlənməsi sizin üçün
            müəyyənləşdirilən xidmət haqqı formasında əks edilməlidir. Sağlamlıq
            planı üzrə hər hansı xidmətə dair haqqın işəgötürəniniz və ya
            agentlik tərəfindən qarşılandığını&nbsp; dəqiqləşdirməyiniz xahiş
            olunur.
          </p>
          <p>
            Sağlamlıq planı, işəgötürən və ya agentlik KursEvi ilə rüsumun və ya
            ödənişin hər hansı bir hissəsini ödəmək üçün bir tədbir görmüşdürsə
            və ya ödəniş KursEvi ilə başqa bir razılaşmaya uyğun olarsa, bu
            ödəniş tənzimlənməsi sizin olduğunuz haqqda əks olunacaq nəticədə
            qeyd olunur. Zəhmət olmasa, hər hansı bir xidmətin geri
            qaytarılacağını müəyyən etmək üçün işəgötürəninizə, sağlamlıq
            planınıza və ya agentliyinizə müraciət edin.
          </p>
          <p>
            <strong>
              Xidmətlər üçün sığorta paketiniz olmazsa və ya sığorta paketindən
              imtina edilərsə, bu halda siz bütün çəkilən xərclər üçün şəxsən
              özünüzün məsuliyyət daşıyacağınızı qəbul edirsiniz.{" "}
            </strong>
            KursEvi sizin hər hansı belə ödənişi qəbul etməyiniz üçün heç bir
            zəmanət təklif etmir. KursEvi bu İstifadə Şərtlərinə müvafiq olaraq
            gələcək ödənişlər və ya ilkin ödəniş üçün fakturanın sizə
            göndərilməsindən əvvəl istənilən vaxt yeni qiymət strukturunu
            dəyişdirmək və ya həyata keçirmək hüququna malikdir.{" "}
            <strong>
              Siz başa düşür və razılaşırsınız ki, təyinat əsasında təmin edilən
              Xidmətlər üçün biz sizə daha qısa ləğvetmə / təxirəsalma
              pəncərəsinin tətbiq edildiyi barədə yazılı şəkildə məlumat
              verməsək, ən azından 24 saat əvvəlcədən planlaşdırılmış təyinatı
              ləğv etməsəniz, planlaşdırılmış xidmətlər üçün siz və ya sizin
              sığortaçınız və ya &nbsp;digər ödəyicinin ödəyəcəyi haqların tam
              &nbsp;və ya müəyyən hissəsinə bərabər olan buraxılmış təyinat
              haqqı üçün özünüz məsuliyyət daşıyacaqsınız.{" "}
            </strong>
          </p>
          <p>
            <strong>
              <u>Xitam</u>
            </strong>
          </p>
          <p>
            Siz istədiyiniz zaman istənilən səbəbdən&nbsp;
            <a href="mailto:destek@ezgil.az">destek@ezgil.az</a> ünvanına e-mail
            (elektron poçt) göndərməklə öz Hesabınızı deaktiv edə və
            qeydiyyatınızı sonlandıra bilərsiniz. “<strong>KursEvi”</strong>
            &nbsp;istənilən vaxt, istənilən səbəbdən sizin Mobil tətbiq və
            saytdan istifadə etməyinizi, Hesabınızı və / və ya qeydiyyatınızı
            dayandıra və ya ona xitam verə bilər. Tətbiq olunan qanuna əsasən, “
            <strong>KursEvi”</strong>
            &nbsp; daxili qeydlərin saxlanması və / və ya məzmunun məhv edilməsi
            siyasətlərinə əsasən Saytda yayımlanan və ya Sayta yüklənən bütün
            kommunikasiyaları və materialları saxlamaq, silmək və ya məhv etmək
            hüququna malikdir. Bu xitamdan sonra “<strong>KursEvi”</strong>
            &nbsp;-un bizim sizə səhiyyə qeydlərinizə giriş hüququnu təmin etmək
            öhdəliyimizdən və ya Tibb Mütəxəssislərindən tətbiq olunan hüquqi,
            etik və peşəkar öhdəliklərə əsasən sizi davamlı qayğı ilə təmin
            etməyin tələb olunduğu hallar istisna olmaqla, Xidmətləri göstərmək
            üçün heç bir digər öhdəliyi olmayacaqdır.
          </p>
          <p>
            <strong>
              <u>Dəyişdirmək hüququ</u>
            </strong>
          </p>
          <p>
            Biz öz qərarımız ilə istənilən vaxt irəli getmək prinsipi əsasında
            bu İstifadə Qaydalarını dəyişə, onlara əlavə edə və ya onların
            müəyyən hissələrini silə bilərik. Hər hansı belə dəyişiklik haqqında
            bildirişdən sonra Sayt və / və ya Xidmətlərdən davamlı istifadə,
            həmin dəyişikliklər daxil olmaqla, yenidən işlənmiş İstifadə
            Qaydalarına əsasən məcburi hesab olunacaq hər hansı dəyişikliyin
            tərəfinizdən təsdiqləndiyini və onlar ilə razılaşmanızı
            bildirəcəkdir.
          </p>
          <p>
            <strong>
              <u>ZƏMANƏTLƏRDƏN İMTİNA </u>
            </strong>
          </p>
          <p>
            BUNUNLA SİZ AÇIQ ŞƏKİLDƏ RAZILAŞIRSINIZ Kİ, SAYTDAN VƏ YA
            XİDMƏTLƏRDƏN İSTİFADƏ RİSKİ YALNIZ SİZƏ AİDDİR. HƏM SAYT, HƏM DƏ
            XİDMƏTLƏR “OLDUĞU KİMİ” VƏ “MÖVCUD” PRİNSİPİ ƏSASINDA TƏMİN EDİLİR.
            “<strong>KursEvi”</strong>&nbsp; AÇIQ ŞƏKİLDƏ&nbsp; İFADƏ OLUNAN VƏ
            YA NƏZƏRDƏ TUTULAN İSTƏNİLƏN NÖV ZƏMANƏTDƏN, O CÜMLƏDƏN SATIŞA
            YARARLILIQ ZƏMANƏTİ, XÜSUSİ MƏQSƏD VƏ İSTİFADƏYƏ UYĞUNLUQ,
            QANUNLARIN POZULMAMASI, MÜLKİYYƏT HÜQUQU, İSTİFADƏ EDİLƏ BİLMƏ,
            VƏZİYYƏT, SAKİT İSTİFADƏ ZƏMANƏTİNDƏN, MƏLUMATLARIN DƏYƏRİ,
            DƏQİQLİYİ VƏ SİSTEM İNTEQRASİYASI İLƏ BAĞLI İSTƏNİLƏN ZƏMANƏTDƏN
            AÇIQ–AŞKAR ŞƏKİLDƏ İMTİNA EDİR.
          </p>
          <p>
            <strong>
              <u>ÖHDƏLİKLƏRİN MƏHDUDİYYƏTİ </u>
            </strong>
          </p>
          <p>
            BAŞA DÜŞÜRSÜNÜZ Kİ, TƏTBİQ OLUNAN QANUNLA YOL VERİLƏN DƏRƏCƏDƏ HEÇ
            BİR HALDA “<strong>KursEvi”</strong>,&nbsp;“
            <strong>KursEvi”-in Mütəxəssisləri</strong>
            <strong>”</strong> VƏ YA ONLARIN ƏMƏKDAŞLARI, İŞÇİLƏRİ,
            DİREKTORLARI, ANA ŞİRKƏTLƏRİ, TÖRƏMƏ MÜƏSSİSƏLƏRİ, FİLİALLARI,
            AGENTLƏRİ VƏ YA LİSENZİYAÇILARI HƏR HANSI DOLAYI, ŞƏRTİ, XÜSUSİ VƏ
            YA NÜMUNƏ XARAKTERLİ ZƏRƏRLƏR, O CÜMLƏDƏN GƏLİR, MƏNFƏƏT, QUDVİL,
            İSTİFADƏ İTKİSİ VƏ YA HƏMİN ZƏRƏRLƏRİN MÜQAVİLƏ, VƏTƏNDAŞ
            İNTİZAMSIZLIĞI (LAQEYDLİK VƏ CİDDİ ÖHDƏLİK DAXİL OLMAQLA), QANUNİ VƏ
            YA DİGƏR ŞƏKİLDƏ ZƏMANƏT ƏSASINDA BAŞ VERİB – VERMƏMƏSİNDƏN ASILI
            OLMAYARAQ, SAYTDAN VƏ YA XİDMƏTLƏRDƏN İSTİFADƏ ETMƏYİNİZ İLƏ BAĞLI
            MƏLUMAT VƏ YA DİGƏR QEYRİ –MADDİ İTKİLƏR ÜÇÜN HEÇ&nbsp; BİR ÖHDƏLİK
            DAŞIMAYACAQDIR.
          </p>
          <p>
            Tətbiq olunan qanuna əsasən hər hansı nəzərdə tutulan zəmanətdən
            imtina edə və ya onun öhdəliklərini məhdudlaşdıra bilməyəcəyimiz
            halda, bu zəmanətin həcmi və müddəti və öhdəliklərimizin əhatə
            dairəsi həmin tətbiq olunan qanuna əsasən minimum yol verilən həddə
            olacaqdır.
          </p>
          <p>
            <strong>
              <u>Zərərlərin əvəzinin ödənilməsi (təzminat) </u>
            </strong>
          </p>
          <p>
            Siz “<strong>KursEvi”</strong>,&nbsp;
            <strong>KursEvi–in Mütəxəssisləri </strong>və onların əməkdaşlarını,
            direktorlarını, işçilərini, agentlərini, törəmə müəssisələrini,
            filiallarını, lisenziyaçılarını və ya təchizatçılarını hazırki
            İstifadə Qaydalarının tərəfinizdən pozulması nəticəsində baş verən
            və bu şəkildə baş verdiyi iddia olan hər hansı tələb, iddia,
            hərəkət, öhdəlik və hesablaşmalara, o cümlədən əsaslı hüquqi və
            &nbsp;mühasibat uçotu haqqlarına (“İddialar”) qarşı qorumağa,
            müdafiə etməyə və zərərlərin əvəzini ödəməyə razılaşırsınız.
          </p>
          <p>
            <strong>
              <u>Coğrafi məhdudiyyətlər </u>
            </strong>
          </p>
          <p>
            <strong>“KursEvi”&nbsp;</strong>Mobil Tətbiqdə və Saytda və ya Sayt
            vasitəsilə Xidmətlərdə təsvir olunan bütün məhsulların, xidmətlərin
            və / və ya materialların Azərbaycandan kənarda digər ölkələrdə və ya
            Azərbaycan daxilində bütün ərazilərdə istifadə üçün uyğun və ya
            mövcud olduğuna dair heç bir təminat vermir.
          </p>
          <p>
            <strong>
              <u>Məlumatların açıqlanması</u>
            </strong>
          </p>
          <p>
            Saytda lisenziyalaşdırılmış klinik xidmətlər göstərən bütün Tibb
            Mütəxəssisləri fəaliyyət göstərdikləri ölkələrdə peşəkar
            lisenziyalaşdırma qurumları və ya agentlikləri tərəfindən təqdim
            olunan peşəkar lisenziyalara sahibdirlər. Bütün həkimlər və
            psixoloqlar, tibb və ya psixologiya üzrə üstün dərəcəyə sahibdir və
            bir çoxu fəlsəfə üzrə doktor dərəcəsi qazanmışlar.
          </p>
          <p>
            Səhiyyə üzrə peşəkar bir mütəxəssis tərəfindən göstərilən qayğı üzrə
            şikayətinizi adıçəkilən lisenziyalı peşəkar qurum ilə&nbsp; əlaqə
            saxlayaraq onları&nbsp; məlumatlandıra bilərsiniz.
          </p>
          <p>
            Xidmətlərdən istifadə etməyiniz nəticəsində hazırlanan istənilən
            klinik qeydlər, səciyyəvi olaraq ən azı beş il olmaqla, dövlətin
            qanununa əsasən həmin qeydlərin saxlanılmasının tələb olunduğu
            minimum ildən az olmayan müddət ərzində “<strong>KursEvi”</strong>
            -un <strong>Mütəxəssisləri</strong> adından “
            <strong>KursEvi”</strong>&nbsp; tərəfindən etibarlı şəkildə
            saxlanılacaqdır.
          </p>
          <p>
            <strong>
              <u>Vebsayt keçidləri </u>
            </strong>
          </p>
          <p>
            BİZ HƏR HANSI DİGƏR VEBSAYT, İNTERNET MƏKANI VƏ YA MƏLUMAT
            MƏNBƏYİNDƏ TAPILAN HƏR HANSI MƏLUMAT, PROQRAM TƏMİNATI VƏ YA
            KEÇİDLƏR, NƏ DƏ SİZİN HƏMİN MƏLUMAT, PROQRAM TƏMİNATI VƏ YA
            KEÇİDLƏRDƏN İSTİFADƏNİZ, NƏ DƏ HƏR HANSI BELƏ VEBSAYT YAXUD ONLARIN
            MÜVAFİQ OPERATORLARININ HƏRƏKƏT VƏ YA HƏRƏKƏTSİZLİYİ ÜÇÜN HEÇ BİR
            CAVABDEHLİK DAŞIMAYACAĞIQ.
          </p>
          <p>
            <strong>
              <u>Mülkiyyət hüququ / sahiblik </u>
            </strong>
          </p>
          <p>
            Mobil tətbiq və Sayt və onun bütöv məzmunu, xüsusiyyətləri və
            funksionallığına (o cümlədən bütün məlumatlar, proqram təminatı,
            mətn, displeylər, görüntülər, video və audio qeydlər və onun
            dizaynı, seçilməsi və nizamı) dair sahiblik <strong>KursEvi</strong>
            , onun sahibkar şirkəti, lisenziyaçıları və həmin materialların
            digər təminatçılarına aiddir və onlar Azərbaycan Respublikası və
            beynəlxalq müəllif hüquqları, əmtəə nişanları, patent, ticarət sirri
            və digər əqli mülkiyyət və ya mülkiyyətçi hüquqları haqqında
            qanunlar ilə qorunur. Hazırki İstifadə Qaydaları sizə Mobil Tətbiq
            və Saytdan yalnız şəxsi, qeyri–kommersiya məqsədilə istifadə etməyə
            icazə verir. Siz, bu İstifadə Qaydalarına əsasən Mobil Tətbiq və
            Saytda ümumi olaraq və adətən icazə verilən hallar istisna olmaqla,
            Mobil Tətbiq və Saytımızda olan hər hansı materialı çoxaltmamalı,
            yaymamalı, dəyişdirməməli, onların törəmələrini yaratmamalı, onları
            açıq şəkildə nümayiş etdirməməli, yenidən dərc etdirməməli,
            yükləməməli, saxlamamalı və ya başqalarına ötürməməlisiniz. Siz
            Mobil Tətbiq və Saytın hər hansı hissəsinə və ya onlarda mövcud olan
            hər hansı xidmət yaxud materiala hər hansı kommersiya məqsədi üçün
            daxil olmamalı və ya istifadə etməməlisiniz.
          </p>
          <p>
            <strong>
              <u>Əmtəə nişanları</u>
            </strong>
          </p>
          <p>
            Mobil Tətbiq və Saytda və ya Xidmətlərdə nümayiş olunan müəyyən
            adlar, loqolar və digər materiallar <strong>KursEvi</strong>
            &nbsp; və ya digər qurumların əmtəə nişanları, ticarət adları,
            xidmət markaları və ya loqolarından (“Markalar”) təşkil oluna bilər.
            “<strong>KursEvi”</strong>&nbsp;- un açıq yazılı icazəsi olmadan
            sizə həmin Markalardan istifadə etmək icazəsi verilmir. Bütün belə
            markalara və onlar ilə bağlı mülkiyyət hüququ bizə və ya həmin digər
            qurumlara aiddir.
          </p>
          <p>
            <strong>
              <u>Məxfilik və kommunikasiya məlumatları </u>
            </strong>
          </p>
          <p>
            <strong>KursEvi</strong>–dən sizin sağlamlıq məlumatlarınızın
            təhlükəsizliyini qorumaq üçün səhiyyə məlumatlarının məxfiliyi və
            təhlükəsizliyinə dair qanunlarına əməl etmək və ehtiyat tədbirləri
            görmək tələb olunur. Bundan əlavə, sağlamlıqla bağlı məsləhətləşmə,
            tibbi konsultasiya və ya terapiya sessiyası zamanı Tibb
            Mütəxəssisinə təqdim etdiyiniz məlumatlar, Məxfilik Siyasətimizdə
            daha ətraflı şəkildə təsvir olunan müəyyən hüquqi istisnalardan
            başqa digər hallarda, hüquqi baxımdan məxfidir. Şəxsi
            məlumatlarınızın təhlükəsiz olmasını təmin etmək üçün hər cür səy
            göstəririk. Sağlamlıq və digər şəxsi məlumatlarınızdan istifadə ilə
            bağlı məlumatlar bizim Məxfilik Siyasətimizdə təsvir olunur.
          </p>
          <p>
            Sizi Xidmətlərimiz ilə təmin etməyin bir hissəsi olaraq, biz,
            təyinat /görüş xatırladıcıları, xidmət elanları, məxfilik
            bildirişləri, inzibati mesajlar və Xidmətlər haqqında digər
            məlumatlar kimi müəyyən məlumatlar (“Kommunikasiya məlumatları”) ilə
            təmin etməli ola bilərik. Bu məlumatlar Xidmətlərin və Hesabınızın
            bir hissəsi hesab olunur.
          </p>
          <p>
            Hazırki İstifadə Qaydaları üçün “razıyam” xanasını və /və ya Mobil
            Tətbiq və Saytda sizə təqdim olunan hər hansı digər formaları işarə
            etməklə, siz, hazırki İstifadə Qaydaları ilə razılaşırsınız və bu
            hərəkət hüquqi imza hesab olunur. Siz razılaşırsınız ki,
            aşağıdakılar daxil olmaqla, lakin onlar ilə məhdudlaşmamaqla, biz
            Kommunikasiya məlumatlarını sizə elektron vasitələr ilə göndərə
            bilərik: (1) elektron poçt ilə qeydiyyat zamanı bizə təqdim
            etdiyiniz ünvandan istifadə edərək, (2) qısa mesajlaşma xidməti
            (“SMS”), qeydiyyat zamanı bizə təqdim etdiyiniz mobil nömrəyə mesaj
            göndərməklə (“SMS mesajlar”), (3) planşet və ya mobil cihazınızda
            bildirişlər və ya (4) Mobil Tətbiq və Saytda Kommunikasiya
            Məlumatlarını yayınlamaqla. Hər hansı Kommunikasiya Məlumatının
            tərəfimizdən çatdırılması o zaman qüvvədə hesab olunur ki,
            oxuyub–oxumamağınızdan asılı olmayaraq, biz onu sizə göndərək. Siz
            Hesabınızı deaktiv edərək Kommunikasiya Məlumatlarını qəbul etmək
            razılığınızı geri götürə bilərsiniz. Təhlükəsiz olmayan email ilə
            müqayisədə təhlükəsiz elektron mesajlaşmaya hər zaman üstünlük
            verilsə də, müəyyən hallarda sizin ilə “<strong>KursEvi</strong>
            <strong>”</strong> arasında şəxsi sağlamlıq məlumatlarınızdan ibarət
            təhlükəsiz olmayan email əlaqəsi də mövcud ola bilər.
          </p>
          <p>
            “<strong>KursEvi”</strong>&nbsp; email ilə göndərilən mesajların
            təhlükəsizliyini və ya məxfiliyini təmin edir. Sizin qayğınız ilə
            bağlı məlumatlar, o cümlədən klinik və tibbi qeydlər “
            <strong>KursEvi” </strong>tərəfindən saxlanılan təhlükəsiz,
            şifrələnmiş serverlərdə saxlanılır.
            <br />{" "}
            <strong>
              <u>Digər </u>
            </strong>
          </p>
          <p>
            Hazırki İstifadə Qaydaları və sizin Saytdan istifadəniz ziddiyyətli
            qanunlar prinsipini tətbiq etmədən Azərbaycan qanunları ilə
            tənzimlənəcəkdir. BU İSTİFADƏ QAYDALARI İLƏ BAĞLI HƏR HANSI ŞƏKİLDƏ
            YARANAN HƏR HANSI MÜBAHİSƏLİ MƏSƏLƏ MÜSTƏSNA OLARAQ AZƏRBAYCANDA
            YEKUN VƏ MƏCBURİ ARBİTRAJ MƏHKƏMƏSİNDƏ HƏLL EDİLƏCƏKDİR. ARBİTRAJ
            YOLU İLƏ VƏ YA DİGƏR ŞƏKİLDƏ BÜTÜN İDDİALAR HƏR HANSI QRUP VƏ YA
            KOLLEKTİV İCRAATDA İDDİAÇI VƏ YA QRUP ÜZVÜ KİMİ DEYİL, YALNIZ FƏRDİ
            OLARAQ SİZƏ AİD OLACAQDIR. Tərəflər bu İstifadə Qaydaları ilə bağlı
            hər hansı hərəkət üçün şəxsi və predmet yurisdiksiyası və
            Azərbaycanda yerləşən məhkəmə yerləri ilə razılaşırlar.
          </p>
          <p>
            <strong>KursEvi</strong>&nbsp; - un bu İstifadə Qaydalarında
            müəyyənləşdirilən hər hansı şərtdən və ya qaydadan imtinası həmin
            şərt və ya qaydadan sonrakı və ya davamlı imtinanı bildirmir&nbsp;
            və ya &nbsp;“<strong>KursEvi</strong>” -un bu İstifadə Qaydalarına
            əsasən hər hansı hüquq və ya müddəanı təsdiq etməməsi həmin hüquq və
            ya müddəadan imtinası hesab olunmur. Bu İstifadə Qaydalarının hər
            hansı müddəası səlahiyyətli yurisdiksiya məhkəməsi və ya digər
            tribunalı tərəfindən hər hansı səbəbdən qanunsuz, etibarsız və ya
            qanuni qüvvəsini itirmiş hesab olunarsa, bu zaman həmin müddəa
            İstifadə Qaydalarının qalan müddəalarının tam olaraq qüvvədə qaldığı
            minimum həddə kənarlaşdırılacaq və ya məhdudlaşdırılacaqdır.
          </p>
          <p>
            <strong>“</strong>
            <strong>KursEvi</strong>
            <strong>”</strong>&nbsp; siqnal gücünün və diaqnoz
            çatışmazlıqlarının optimallaşdırılması üçün əhəmiyyətli dərəcədə
            cəhd göstərir, lakin o internet və ya verilənlər zolağı və mobil
            cihazınızın siqnalı üçün məsuliyyət daşımır.&nbsp; Hər hansı
            sualınızı və ya bu İstifadə Qaydalarının pozulması ilə bağlı
            məlumatı &nbsp;
            <a href="mailto:destek@ezgil.az">destek@ezgil.az</a> ünvanına
            göndərin.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TermsOfUse;
