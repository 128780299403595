import axios from "axios";
import { createContext, useEffect, useRef, useState } from "react";

export const MainContext = createContext();

export const AppContext = ({ children }) => {
  // Header
  const [sideBar, setSideBar] = useState(false);

  const [contactData, setContactData] = useState(null);

  // Calendar
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [selectRangeDate, setSelectRangeDate] = useState({
    start: "",
    end: "",
  });
  const [handleEdit, setHandleEdit] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [listData, setListData] = useState([]);

  // Porifile
  const [activeProfileTab, setActiveProfileTab] = useState({
    type: "Hesab",
    name_az: "Hesab",
    name_en: "Profile",
    name_ru: "Профиль",
    id: 1,
  });

  // Conferance Course Detail Page SideBar
  const [courseConferanceData, setCourseConferanceData] = useState(null);
  const [filterMenu, setFIlterMenu] = useState(false);
  const [selectSubCategoryId, setSelectSubCategoryId] = useState(null);
  // const [categoryData, setCategoryData] = useState([]);
  const [categoryVisible, setCategoryVisible] = useState(false);
  const [renderType, setRenderType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [paymentVisible, setPaymentVisible] = useState(false);
  const [ticketVisible, setTicketVisible] = useState(false);

  // Function
  useEffect(() => {
    axios
      .get(`https://api.uimconsulting.com/en/v3/sites/contacts`, {
        crossdomain: true,
      })
      .then((res) => {
        setContactData(res.data.payload);
      })
      .catch((err) => err && setContactData({ alert: "not-found" }));
  }, []);

  // Scrool Pay Section in Register Btn onCLick /conferance course detail page
  const scrollRef = useRef(null);

  const scrollToSection = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const startDate = selectRangeDate.start;
    if (startDate !== "") {
      const day = startDate.getDate().toString().padStart(2, "0");
      const month = (startDate.getMonth() + 1).toString().padStart(2, "0");
      const year = startDate.getFullYear().toString();

      setStartDate(`${day}-${month}-${year}`);
    }
  }, [selectRangeDate.start, setStartDate]);

  useEffect(() => {
    const endDate = selectRangeDate.end;
    if (endDate !== "") {
      const day = endDate.getDate().toString().padStart(2, "0");
      const month = (endDate.getMonth() + 1).toString().padStart(2, "0");
      const year = endDate.getFullYear().toString();

      setEndDate(`${day}-${month}-${year}`);
    }
  }, [selectRangeDate.end]);

  const globalStates = {
    // Header
    sideBar,
    setSideBar,

    contactData,

    // Calendar
    calendarVisible,
    setCalendarVisible,
    selectRangeDate,
    setSelectRangeDate,
    handleEdit,
    setHandleEdit,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    listData,
    setListData,

    // Profile
    activeProfileTab,
    setActiveProfileTab,

    // Conferance Course Detail Page SideBar
    courseConferanceData,
    setCourseConferanceData,
    filterMenu,
    setFIlterMenu,
    selectSubCategoryId, setSelectSubCategoryId,
    // categoryData,
    // setCategoryData,
    categoryVisible,
    setCategoryVisible,
    renderType,
    setRenderType,
    searchValue,
    setSearchValue,
    paymentVisible,
    setPaymentVisible,
    ticketVisible,
    setTicketVisible,

    // function
    scrollRef,
    scrollToSection,
  };
  return (
    <MainContext.Provider value={globalStates}>{children}</MainContext.Provider>
  );
};
