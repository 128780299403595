import React from "react";
import { useTranslation } from "react-i18next";

const GeneralInfo = ({ data,type }) => {
  const{t}=useTranslation()
  return (
    <section className="general-info-detail">
      <div className="container">
        <div className="bg-text">
          <span className="text">{t("detail_general_info.info_bg_text")}</span>
          <h6 className="title">
           {type==="conferences"?t("detail_general_info.info_before_text_conferance") :t("detail_general_info.info_before_text")} <span>{t("detail_general_info.info_after_text")}</span>
          </h6>
        </div>
        <div className="info">
          <div
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          />
          {data.auditory && (
            <>
              <h2 className="title">{t("detail_general_info.auditory")}</h2>
              <p className="text">{data.auditory}</p>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default GeneralInfo;
