const Loading = () => {
  return (
    <section className="loader">
      <div className="loading loading03">
        <span style={{ color: "#0046ac" }}>K</span>
        <span style={{ color: "#0046ac" }}>U</span>
        <span style={{ color: "#0046ac" }}>R</span>
        <span style={{ color: "#0046ac" }}>S</span>
        <span style={{ color: "#0046ac" }}>E</span>
        <span style={{ color: "#0046ac" }}>V</span>
        <span style={{ color: "#0046ac" }}>İ</span>
      </div>
    </section>
  );
};

export default Loading;
